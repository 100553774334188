import * as ActionTypes from './types';
import { actionCreator } from '../helpers/actionCreator';

export const startChat = actionCreator(ActionTypes.START_CHAT);
export const startChatSuccess = actionCreator(ActionTypes.START_CHAT_SUCCESS);
export const startChatFailed = actionCreator(ActionTypes.START_CHAT_FAILED);

export const signalREventChatStarted = actionCreator(ActionTypes.SIGNAL_R_EVENT_CHAT_STARTED);
export const signalREventChatEnded = actionCreator(ActionTypes.SIGNAL_R_EVENT_CHAT_ENDED);
export const signalREventChatNewMessage = actionCreator(ActionTypes.SIGNAL_R_EVENT_CHAT_NEW_MESSAGE);

export const sendMessage = actionCreator(ActionTypes.SEND_MESSAGE);
export const sendMessageSuccess = actionCreator(ActionTypes.SEND_MESSAGE_SUCCESS);
export const sendMessageFailed = actionCreator(ActionTypes.SEND_MESSAGE_FAILED);

export const getMessages = actionCreator(ActionTypes.GET_MESSAGES);
export const getMessagesSuccess = actionCreator(ActionTypes.GET_MESSAGES_SUCCESS);
export const getMessagesFailed = actionCreator(ActionTypes.GET_MESSAGES_FAILED);

export const getChatInfoSuccess = actionCreator(ActionTypes.GET_CHAT_INFO_SUCCESS);
export const getChatInfoFailed = actionCreator(ActionTypes.GET_CHAT_INFO_FAILED);

export const storeChatSubject = actionCreator(ActionTypes.STORE_CHAT_SUBJECT);

export const sendEndFeeling = actionCreator(ActionTypes.SEND_END_FEELING);
export const sendEndFeelingSuccess = actionCreator(ActionTypes.SEND_END_FEELING_SUCCESS);
export const sendEndFeelingFailed = actionCreator(ActionTypes.SEND_END_FEELING_FAILED);
