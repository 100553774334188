import { fromJS } from 'immutable';
import * as ActionTypes from '../actions/types';
import { createReducer } from './index';

const INITIAL_STATE = fromJS({
  loading: false,
  acceptTerms: false,
  title: "",
  body: "",
});

function acceptTerms(state) {
  return state.set('loading', true);
}

function acceptTermsSuccess(state) {
  return state
    .set('acceptTerms', true)
    .set('loading', false);
}

function acceptTermsFailed(state, action) {
  // TODO: Proper error message
  return state
    .set('loading', false)
    .set('errorMessage', 'ERROR MESSAGE')
}

function getTerms(state,action) {
  return state.set('loading', true);
}

function getTermsSuccess(state, action) {
  return state
    .set('title', action.payload.body.result.title)
    .set('body', action.payload.body.result.body)
    .set('loading', false);
}

function getTermsFailed(state, action) {
  return state
    .set('loading', false);
}

function logout(state, action){
  return state.set('acceptTerms', false);
}

export const terms = createReducer(INITIAL_STATE, {
  [ActionTypes.GET_TERMS]: getTerms,
  [ActionTypes.GET_TERMS_SUCCESS]: getTermsSuccess,
  [ActionTypes.GET_TERMS_FAILED]: getTermsFailed,
  [ActionTypes.ACCEPT_TERMS]: acceptTerms,
  [ActionTypes.ACCEPT_TERMS_SUCCESS]: acceptTermsSuccess,
  [ActionTypes.ACCEPT_TERMS_FAILED]: acceptTermsFailed,
  [ActionTypes.LOGOUT]: logout,
});
