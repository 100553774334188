// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import ChatHomeScreen from '../components/chatHomeScreen/chatHomeScreen';
import { storeChatSubject } from '../actions';

type Props = {
  storeChatSubject: Function,
};

type State = {
  chatSubject: string,
};

class ChatHomeScreenHOC extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      chatSubject: '',
    };
  }

  handleChange = (event) => {
    this.setState({chatSubject: event.target.value});
  };

  componentDidMount() {
  }

  render() {
    return (
      <ChatHomeScreen
        handleChange={this.handleChange}
        chatSubject={this.state.chatSubject}
        {...this.props} />
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  storeChatSubject: (payload) => {
    dispatch(storeChatSubject(payload))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatHomeScreenHOC);
