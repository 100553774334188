import React from 'react';
import Feeling from './feeling';
import FEELINGS from '../../constants/feelings';
import './feelingsScreen.css';

type Props = {
  startChat: Function,
  feelingSelected: string,
  setFeelingSelected: Function,
  end?: boolean,
};

const FeelingsScreen = (props: Props) => {
  const { startChat, feelingSelected, setFeelingSelected, end } = props;
  return (
    <div className="feelingsScreenContainer">
      <h1>{end ? "Πώς νιώθεις μετά το chat;" : "Πώς νιώθεις;"}</h1>
      <div className="feelingsContainer">
        {FEELINGS.map(feeling => (
          <Feeling
            feelingGR={feeling.feelingGr}
            key={'feeling-' + feeling.id}
            id={feeling.id}
            image={feeling.image}
            startChat={startChat}
            feelingSelected={feelingSelected}
            setFeelingSelected={setFeelingSelected}
          />
        ))}
      </div>
    </div>
  );
};

FeelingsScreen.defaultProps = {
  end: false
};

export default FeelingsScreen;
