// User
export const REGISTER_CHILD = 'REGISTER_CHILD';
export const REGISTER_CHILD_SUCCESS = 'REGISTER_CHILD_SUCCESS';
export const REGISTER_CHILD_FAILED = 'REGISTER_CHILD_FAILED';
export const USER_IS_UNAUTHORIZED = 'USER_IS_UNAUTHORIZED';
export const LOGOUT = 'LOGOUT';

// Terms
export const ACCEPT_TERMS = 'ACCEPT_TERMS';
export const ACCEPT_TERMS_SUCCESS = 'ACCEPT_TERMS_SUCCESS';
export const ACCEPT_TERMS_FAILED = 'ACCEPT_TERMS_FAILED';
export const GET_TERMS = 'GET_TERMS';
export const GET_TERMS_FAILED = 'GET_TERMS_FAILED';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';

//signalR
export const SIGNAL_R_INFO = 'SIGNAL_R_INFO';
export const SIGNAL_R_DISCONNECT = 'SIGNAL_R_DISCONNECT';

// Chat
export const START_CHAT = 'START_CHAT';
export const START_CHAT_SUCCESS = 'START_CHAT_SUCCESS';
export const START_CHAT_FAILED = 'START_CHAT_FAILED';

export const SIGNAL_R_EVENT_CHAT_STARTED = 'SIGNAL_R_EVENT_CHAT_STARTED';
export const SIGNAL_R_EVENT_CHAT_ENDED = 'SIGNAL_R_EVENT_CHAT_ENDED';
export const SIGNAL_R_EVENT_CHAT_NEW_MESSAGE = 'SIGNAL_R_EVENT_CHAT_NEW_MESSAGE';
export const CHILD_STARTED_CHAT = 'CHILD_STARTED_CHAT';

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';

export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED';

export const STORE_CHAT_SUBJECT = 'STORE_CHAT_SUBJECT';

export const GET_CHAT_INFO_SUCCESS = 'GET_CHAT_INFO_SUCCESS';
export const GET_CHAT_INFO_FAILED = 'GET_CHAT_INFO_FAILED';

export const SEND_END_FEELING = 'SEND_END_FEELING';
export const SEND_END_FEELING_SUCCESS = 'SEND_END_FEELING_SUCCESS';
export const SEND_END_FEELING_FAILED = 'SEND_END_FEELING_FAILED';

// Connectivity
export const CHECK_CONNECTIVITY = 'CHECK_CONNECTIVITY';
export const CHECK_CONNECTIVITY_SUCCESS = 'CHECK_CONNECTIVITY_SUCCESS';
export const CHECK_CONNECTIVITY_FAILED = 'CHECK_CONNECTIVITY_FAILED';

// Pages
export const GET_PAGES = 'GET_PAGES';
export const GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS';
export const GET_PAGES_FAILED = 'GET_PAGES_FAILED';

export const GET_SOS_LINE_PAGES = 'GET_SOS_LINE_PAGES';
export const GET_SOS_LINE_PAGES_SUCCESS = 'GET_SOS_LINE_PAGES_SUCCESS';
export const GET_SOS_LINE_PAGES_FAILED = 'GET_SOS_LINE_PAGES_FAILED';

// Redirects
export const REDIRECT_TO_VIEW_TERMS = 'REDIRECT_TO_VIEW_TERMS';

// Things to do
export const SELECT_SCREEN_IN_THINGS_TO_DO = 'SELECT_SCREEN_IN_THINGS_TO_DO';

// General
export const SET_TAB_VISIBILITY = 'SET_TAB_VISIBILITY';
export const SET_USER_AGENT_INFO = 'SET_USER_AGENT_INFO';
export const NULL_ACTION = 'NULL_ACTION';
