// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import PagesTypes from '../constants/pagesTypes';
import VideoScreen from '../components/video/videoScreen';
import getVideo from '../components/immutableParsers/getVideo';

type Props = {
  match: Object,
  videos: Object,
};

class VideoHOC extends React.Component<Props> {
  render() {
    const videoId = this.props.match.params.videoId;
    const { videos } = this.props;

    return (
      <VideoScreen
        video={getVideo(videos, videoId)}
      />
    );
  }
}

const mapStateToProps = state => ({
  videos: state.pages.get(PagesTypes.VIDEO)
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VideoHOC);
