// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import ThingsToDoScreen from '../components/thingsToDo/thingsToDoScreen';
import ThingsToDoScreens from '../constants/thingsToDoScreens';
import { getPages } from '../actions';
import parsePages from '../components/immutableParsers/parsePages';
import { selectScreenInThingsToDo } from '../actions/thingsToDo';

type Props = {
  getPages: Function,
  pages: Object,
  chatId?: string,
  history: Object,
  activeAgents?: number,
  selectScreenInThingsToDo: Function,
  screenSelected: string,
  isMobileSafari: boolean,
};

var $status = document.getElementById('status');

class ThingsToDoHOC extends React.Component<Props> {
  componentDidMount() {
    setTimeout(() => {
      this.props.getPages();
    }, 1); // When coming from rehydrate this action gets messed with other actions
    this.requestPermission();
  }

  chooseScreen = (event) => {
    this.props.selectScreenInThingsToDo(event.currentTarget.id);
  };

  requestPermission = () => {
    if (!('Notification' in window) || this.props.isMobileSafari) {
      // alert('Notification API not supported!');
      return;
    }

    Notification.requestPermission(function (result) {
      // $status.innerText = result;
    });
  };

  render() {
    return (
      <ThingsToDoScreen
        screenSelected={this.props.screenSelected}
        chooseScreen={this.chooseScreen}
        pages={parsePages(this.props.pages)}
        chatId={this.props.chatId}
        history={this.props.history}
        activeAgents={this.props.activeAgents}
      />
    );
  }
}

const mapStateToProps = state => ({
  pages: state.pages,
  chatId: state.chat.get('id'),
  isMobileSafari: state.general.get('isMobileSafari'),
  activeAgents: state.chat.get('activeAgents'),
  screenSelected: state.thingsToDo.get('screenSelected')
});

const mapDispatchToProps = dispatch => ({
  getPages: (payload) => {
    dispatch(getPages(payload))
  },
  selectScreenInThingsToDo: (payload) => {
    dispatch(selectScreenInThingsToDo(payload))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ThingsToDoHOC);
