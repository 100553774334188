import * as React from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../style/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Send from '@material-ui/icons/Send';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

type Props = {
  sendMessage: Function,
};

type State = {
  value: string,
  rows: number,
  minRows: number,
  maxRows: number,
};

class AutogrowingTextInput extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      rows: 1,
      minRows: 1,
      maxRows: 2,
    };
  }

  handleChange = (event) => {
    const textareaLineHeight = 18;
    const { minRows, maxRows } = this.state;

    const previousRows = event.target.rows;
    event.target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    this.setState({
      value: event.target.value,
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
  };

  sendMessage = (e) => {
    if (e.keyCode === 13
      && !e.shiftKey
      && e.target.value.trim().length
    ) {
      e.preventDefault();
      e.stopPropagation();
      this.props.sendMessage(e.target.value);
      // I want to clear the textarea around here
      this.setState({ value: "" })
    }
  };

  tapButtonToSendMessage = () => {
    if (this.state.value.trim().length) {
      this.props.sendMessage(this.state.value);
      this.setState({ value: "" });
    }
  };

  render() {
    return (
      <div className="writeMessageContainer">
      <textarea
        rows={this.state.rows}
        value={this.state.value}
        placeholder={'Γράψε το μήνυμα σου...'}
        className={'chatTextInput'}
        onChange={this.handleChange}
        onKeyDown={this.sendMessage}
      />
        <IconButton color="primary" aria-label="close" onClick={() => this.tapButtonToSendMessage()}>
          <Send />
        </IconButton>
      </div>
    );
  }
}

export default AutogrowingTextInput;
