import ThingsToDoMessages from '../constants/thingsToDoMessages';
import { MINIMUM_NUMBER_OF_AGENTS_TO_DECLARE_WAITING } from '../config';

const determineWaitingMessage = (activeAgents) => {
  let className = ThingsToDoMessages.STARTS_SOON.className;
  let message= ThingsToDoMessages.STARTS_SOON.message;
  if (activeAgents === 0) {
    className = ThingsToDoMessages.NOT_AVAILABLE.className;
    message = ThingsToDoMessages.NOT_AVAILABLE.message;
  }
  if (activeAgents < MINIMUM_NUMBER_OF_AGENTS_TO_DECLARE_WAITING){
    className = ThingsToDoMessages.WAITING.className;
    message = ThingsToDoMessages.WAITING.message;
  }
  return {
    message,
    className,
  }
};

export default determineWaitingMessage;
