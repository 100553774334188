import React from 'react';
import './thingsToDoScreen.css';
import WaitingMessage from './waitingMessage';
import ThingsToDoMessages from '../../constants/thingsToDoMessages';
import CategoryChooser from './categoryChooser';
import Pages from './pages';

type Props = {
  screenSelected: string,
  chooseScreen: Function,
  pages: Object,
  chatId?: string,
  history: Object,
  activeAgents?: number,
};

const ThingsToDoScreen = (props: Props) => {
  const { screenSelected, chooseScreen, pages, chatId, history, activeAgents } = props;
  return (
    <div className="thingsToDoScreenContainer">
      {chatId ? <WaitingMessage
        activeAgents={activeAgents}
      /> : null}
      <h1 className="somethingToDoHeader">Κάτι για να περιμένω</h1>
      <CategoryChooser
        screenSelected={screenSelected}
        chooseScreen={chooseScreen}
      />
      <Pages
        page={pages[screenSelected]}
        category={screenSelected}
        history={history}
      />
    </div>
  );
};

export default ThingsToDoScreen;
