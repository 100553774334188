import { combineCycles } from 'redux-cycles';
import xs from 'xstream';
import * as ActionTypes from '../actions/types';
import { BASE_URL } from '../config';
import * as actions from '../actions';
import {postHeaderWithTokenJson} from "./headers";
import sampleCombine from "xstream/extra/sampleCombine";

export function checkConnectivity(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.get('token'));
  const request$ = sources.ACTION
    .filter(action => action.type === ActionTypes.CHECK_CONNECTIVITY)
    .compose(sampleCombine(token$))
    .map(([action, token]) => ({
      url: BASE_URL + 'status',
      category: 'checkConnectivity',
      headers: postHeaderWithTokenJson(token),
      method: 'GET',
    }));

  let httpResponse$ = sources.HTTP
    .select('checkConnectivity')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map(response => actions.checkConnectivitySuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  }
}

export function checkConnectivityFailed(sources) {
  const response$ = sources.HTTP
    .select('checkConnectivity')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 200);

  const action$ = xs.combine(response$)
    .map(response => actions.checkConnectivityFailed(response));

  return {
    ACTION: action$,
  };
}

export default combineCycles(
  checkConnectivity,
  checkConnectivityFailed,
);
