import getYoutubeVideoIdFromUrl from '../../helpers/getYoutubeVideoIdFromUrl';

const getVideo = (videos, videoId) => {
  const result = videos
    .filter(articleItem =>
      articleItem.get('id') === videoId);

  if (result.size) {
    const theVideo = result.valueSeq().toArray()[0];
    return {
      id: theVideo.get('id'),
      body: theVideo.get('body'),
      imageEncodedFile: theVideo.get('imageEncodedFile'),
      title: theVideo.get('title'),
      url: getYoutubeVideoIdFromUrl(theVideo.get('url')),
    };
  }
  return undefined;
};

export default getVideo;
