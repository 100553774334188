import PagesTypes from '../../constants/pagesTypes';

const parsePages = (pages) => {
  if (!pages) {
    return {
      [PagesTypes.ARTICLE]: [],
      [PagesTypes.GAMES]: [],
      [PagesTypes.STATIC]: [],
      [PagesTypes.VIDEO]: [],
    }
  }

  const articles = pages.get(PagesTypes.ARTICLE)
    .valueSeq().toArray()
    .map(article => ({
      id: article.get('id'),
      body: article.get('body'),
      title: article.get('title'),
    }));

  const games = pages.get(PagesTypes.GAMES)
    .valueSeq().toArray()
    .map(article => ({
      id: article.get('id'),
      body: article.get('body'),
      title: article.get('title'),
    }));

  const staticPages = pages.get(PagesTypes.STATIC)
    .valueSeq().toArray()
    .map(article => ({
      id: article.get('id'),
      body: article.get('body'),
      title: article.get('title'),
    }));

  const videos = pages.get(PagesTypes.VIDEO)
    .valueSeq().toArray()
    .map(article => ({
      id: article.get('id'),
      body: article.get('body'),
      title: article.get('title'),
    }));

  return {
    [PagesTypes.ARTICLE]: articles,
    [PagesTypes.GAMES]: games,
    [PagesTypes.STATIC]: staticPages,
    [PagesTypes.VIDEO]: videos,
  }
};

export default parsePages;
