// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import FeelingsScreen from '../components/feelings/feelingsScreen';
import { logout, sendEndFeeling } from '../actions';
import FEELINGS from '../constants/feelings';

type Props = {
  sendEndFeeling: Function,
  history: Object,
  logout: Function,
};

type State = {
  feelingSelected?: string,
}

class EndFeelingsHOC extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      feelingSelected: undefined
    }
  }

  setFeelingSelected = (event) => {
    this.setState({
      feelingSelected: event.currentTarget.id
    });

    const feelingSelected = FEELINGS
      .filter(feeling => feeling.id === event.currentTarget.id)[0];
    this.props.sendEndFeeling({
      feeling: feelingSelected.feeling
    });
    setTimeout(() => {this.props.logout()}, 10);
  };

  render() {
    return (
      <FeelingsScreen
        feelingSelected={this.state.feelingSelected}
        setFeelingSelected={this.setFeelingSelected}
        {...this.props}
        end
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  sendEndFeeling: (payload) => {
    dispatch(sendEndFeeling(payload))
  },
  logout: (payload) => {
    dispatch(logout(payload))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EndFeelingsHOC);
