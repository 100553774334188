// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import ArticleScreen from '../components/article/articleScreen';
import PagesTypes from '../constants/pagesTypes';
import getArticle from '../components/immutableParsers/getArticle';

type Props = {
  match: Object,
  articles: Object,
};

class ArticleHOC extends React.Component<Props> {
  render() {
    const articleId = this.props.match.params.articleId;
    const { articles } = this.props;

    return (
      <ArticleScreen
        article={getArticle(articles, articleId)}
      />
    );
  }
}

const mapStateToProps = state => ({
  articles: state.pages.get(PagesTypes.ARTICLE)
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleHOC);
