import React from 'react';
import './chatScreen.css';
import ChatWindow from './chatWindow';
import ChatHeaderTimeRemaining from './timeRemaining';
import AutogrowingTextInput from './autogrowingTextInput';

type Props = {
  messages: Array<Object>,
  sendMessage: Function,
  timeRemaining: Object,
};

type State = {
  isFocused: boolean,
  messageTyped: string,
}

class ChatScreen extends React.Component<Props, State> {
  render() {
    const { messages, sendMessage, timeRemaining } = this.props;
    return (
      <div className="chat">
        <ChatHeaderTimeRemaining
          timeRemaining={timeRemaining}
        />
        <ChatWindow messages={messages}/>
          <AutogrowingTextInput
            sendMessage={sendMessage}
          />
      </div>
    );
  }
};

export default ChatScreen;
