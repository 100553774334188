import React from 'react';
import Images from '../../../assets/images';
import './loadingScreen.css';

const LoadingScreen = () => {
  return (
    <div className="loadingScreen">
       <img
         src={Images.LOGO_SQUARE}
         alt={"logo"}
       />
      <span>
        Παρακαλώ περιμένετε...
      </span>
    </div>
  );
};

export default LoadingScreen;
