import React from 'react';
import QuestionAnswer from './questionAnswer';
import createMarkup from '../../helpers/createHtmlMarkup';

type Props = {
  body: string,
};

const Terms = (props: Props) => {
  return (
    <div>
      <div
        className="termsFromHtmlContainer"
        dangerouslySetInnerHTML={createMarkup(props.body)} />
    </div>
  );
};

export default Terms;
