import xs from 'xstream';
import { combineCycles } from 'redux-cycles';
import sampleCombine from 'xstream/extra/sampleCombine';
import * as ActionTypes from '../actions/types';
import { BASE_URL } from '../config';
import { postHeaderWithTokenJson } from './headers';
import * as actions from '../actions';

export function getSosLinePages(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.get('token'));

  const request$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_SOS_LINE_PAGES)
    .compose(sampleCombine(token$))
    .map(([action, token]) => ({
      url: BASE_URL + 'pages/SOSLine',
      category: 'getSosLinePages',
      method: 'GET',
      headers: postHeaderWithTokenJson(token),
    }));

  let httpResponse$ = sources.HTTP
    .select('getSosLinePages')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map(response => actions.getSosLinePagesSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  }
}

export function getSosLinePagesFailed(sources) {
  const response$ = sources.HTTP
    .select('getSosLinePages')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 200);

  const action$ = xs.combine(response$)
    .map(response => actions.getSosLinePagesFailed(response));

  return {
    ACTION: action$,
  };
}

export default combineCycles(
  getSosLinePages,
  getSosLinePagesFailed
);
