import React from 'react';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import FooterIcon from './footerIcon';

const CallButton = () => {
  return (
    <a className="callButton grow" href="tel:1056">
      <FooterIcon iconName={faPhoneAlt}/>
      SOS 1056
    </a>
  );
};

export default CallButton;
