import xs from 'xstream';
import * as actions from '../actions';
import { combineCycles } from 'redux-cycles';
import * as ActionTypes from '../actions/types';
import { push } from 'react-router-redux';

export function notifyAppOnUnauthorizedCall(sources) {
  const response$ = sources.HTTP
    .select()
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => {
      return response.status === 401
    });

  const action$ = xs.combine(response$)
    .map(() => actions.userIsUnauthorized());

  return {
    ACTION: action$,
  };
}

export function redirectToLoginIfUserIsUnauthorized(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.USER_IS_UNAUTHORIZED)
    .map(() => push('/'));

  return {
    ACTION: action$,
  }
}

export function clearStateIfUserIsUnauthorized(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.USER_IS_UNAUTHORIZED)
    .map(() => actions.logout());

  return {
    ACTION: action$,
  }
}

export default combineCycles(
  notifyAppOnUnauthorizedCall,
  redirectToLoginIfUserIsUnauthorized,
  clearStateIfUserIsUnauthorized
);
