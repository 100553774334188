import React from 'react';
import ThingToDo from './thingToDo';

type Props = {
  page: Object,
  category: string,
  history: Object,
};

const Pages = (props: Props) => {
  const { page, category, history } = props;
  return (
    <div className="pagesThingsToDoContainer">
      {page
        .map(singlePage => (
          <ThingToDo
            key={singlePage.id}
            thingToDo={singlePage}
            category={category}
            history={history}
          />))
      }
    </div>
  );
};

export default Pages;
