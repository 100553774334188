//@flow
import React from 'react';
import { connect } from 'react-redux';
import '../assets/scss/base.css';
import {ALL_USERS, ANONYMOUS, REGISTERED_USERS, USER} from '../constants/UserGroups';
import { logout, redirectToViewTerms } from '../actions';
import { signalRConnect } from '../store/signalRMiddleware';
import store, {history} from '../store/store';
import Header from "../components/header/header";
import NoConnectivity from "../components/general/noConnectivity/noConnectivity";
import Footer from "../components/footer/footer";
import {Route, Router, Switch} from "react-router";
import AuthorizationHOC from "./AuthorizationHOC";
import LoginHOC from "./LoginHOC";
import TermsHOC from "./TermsHOC";
import ViewTermsHOC from "./ViewTermsHOC";
import ChatHomeScreenHOC from "./ChatHomeScreenHOC";
import FeelingsHOC from "./FeelingsHOC";
import ChatHOC from "./ChatHOC";
import ThingsToDoHOC from "./ThingsToDoHOC";
import Unauthorized from "../components/unauthorized/unauthorized";
import {BrowserRouter, HashRouter} from "react-router-dom";
import ArticleHOC from "./ArticleHOC";
import VideoHOC from "./VideoHOC";
import EndFeelingsHOC from './EndFeelingsHOC';
import SwitchTheme from '../components/switchTheme/switchTheme';
import SosLineHOC from './SosLineHOC';

type Props = {
  role: string,
  isOnline: boolean,
  logout: Function,
  token: string,
  chatId: string,
  redirectToViewTerms: Function,
  chatEndTime: number,
};

class LayoutHOC extends React.Component<Props> {
  componentDidMount() {
    if (this.props.token) {
      signalRConnect(store, this.props.token);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!this.props.token && nextProps.token) {
      signalRConnect(store, nextProps.token);
    }
  }

  render(){
    const {
      role,
      isOnline,
      logout,
      redirectToViewTerms,
      chatId,
      chatEndTime
    } = this.props;
    return(
      <Router history={history}>
      <div>
        <Header
          role={role}
          chatId={chatId}
          endTime={chatEndTime}
        />
        {!isOnline ? <NoConnectivity /> : null}
        <div className="content">
            <Switch>
              <Route exact path="/" component={AuthorizationHOC(ALL_USERS)(LoginHOC)}/>
              <Route exact path="/terms" component={AuthorizationHOC(REGISTERED_USERS)(TermsHOC)}/>
              <Route exact path="/viewTerms" component={AuthorizationHOC(ALL_USERS)(ViewTermsHOC)}/>
              <Route exact path="/sosLine" component={AuthorizationHOC(ALL_USERS)(SosLineHOC)}/>
              <Route exact path="/chatHomeScreen" component={AuthorizationHOC(REGISTERED_USERS)(ChatHomeScreenHOC)}/>
              <Route exact path="/feelingBeforeChat" component={AuthorizationHOC(REGISTERED_USERS)(FeelingsHOC)}/>
              <Route exact path="/chat" component={AuthorizationHOC(REGISTERED_USERS)(ChatHOC)}/>
              <Route exact path="/thingsToDo" component={AuthorizationHOC(REGISTERED_USERS)(ThingsToDoHOC)}/>
              <Route path="/thingsToDo/articles/:articleId" component={AuthorizationHOC(ALL_USERS)(ArticleHOC)}/>
              <Route path="/thingsToDo/videos/:videoId" component={AuthorizationHOC(ALL_USERS)(VideoHOC)}/>
              <Route path="/feelingAfterChat" component={AuthorizationHOC(ALL_USERS)(EndFeelingsHOC)}/>
              <Route component={Unauthorized} />
            </Switch>
        </div>
        <SwitchTheme
          defaultComponentBreakpoint="sm"
          config={{
            sm: {
              Component: 'div'
            },
            md: {
              Component: Footer
            },
          }}/>
      </div>
      </Router>
    )
  }
}

const mapStateToProps = (state) => ({
  role: state.user.get('role') === true ? USER : ANONYMOUS,
  isOnline: state.connectivity.get('isOnline'),
  token: state.user.get("token"),
  chatId: state.chat.get("id"),
  chatEndTime: state.chat.get("endTime"),
});

const mapDispatchToProps = dispatch => ({
  logout: (payload) => {
    dispatch(logout(payload))
  },
  redirectToViewTerms: (payload) => {
    dispatch(redirectToViewTerms(payload))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutHOC);
