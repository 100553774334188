// @flow
import React from 'react';
import determineWaitingMessage from '../../helpers/determineWaitingMessage';

type Props = {
  activeAgents?: number,
};

const WaitingMessage = (props: Props) => {
  const { activeAgents } = props;
  const { className, message } = determineWaitingMessage(activeAgents);
  return (
    <div className={`waitingMessage ${className}`}>
      {message}
    </div>
  );
};

export default WaitingMessage;
