// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import ViewTermsScreen from '../components/terms/viewTermsScreen';
import { getTerms } from '../actions';

type Props = {
  loading: boolean,
  title: string,
  body: string,
  getTerms: Function,
};

class ViewTermsHOC extends React.Component<Props> {
  componentDidMount() {
    this.props.getTerms();
  }

  render() {
    const { loading, title, body } = this.props;
    return (
      <ViewTermsScreen
        loading={loading}
        title={title}
        body={body}
      />
    );
  }
}

const mapStateToProps = state => ({
  loading: state.terms.get('loading'),
  title: state.terms.get('title'),
  body: state.terms.get('body'),
});

const mapDispatchToProps = dispatch => ({
  getTerms: (payload) => {
    dispatch(getTerms(payload))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewTermsHOC);
