import React from 'react';
import Message from './message';

type Props = {
  messages: Array<Object>
};

type State = {
  messageTyped: string,
};

class ChatWindow extends React.Component<Props, State> {
  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    const { messages } = this.props;
    return (
      <div className="chatContentContainer">
        <div
          className="chatContent"
        >
          {messages.map(message => (
            <Message
              key={message.id}
              data={message.data}
              isMine={message.isMine}
              startsSequence={message.startsSequence}
              endsSequence={message.endsSequence}
              showTimestamp={message.showTimestamp}
              sendingInProgress={message.sendingInProgress}
            />))}
        </div>
        <div
          style={{ float:"left", clear: "both" }}
          ref={(el) => { this.messagesEnd = el; }}>
        </div>
      </div>
    );
  }
}

export default ChatWindow;
