// @flow
import React from 'react';
import { faBookOpen, faSignOutAlt  } from '@fortawesome/free-solid-svg-icons'
import FooterButton from './footerButton';
import Colors from '../../style/colors';
import './footer.css';
import FooterIcon from './footerIcon';
import CallButton from './callButton';
import { ANONYMOUS, USER } from '../../constants/UserGroups';
import { logout, redirectToViewTerms } from '../../actions';
import { connect } from 'react-redux';

type Props = {
  logout : Function,
  redirectToViewTerms: Function,
};

class Footer extends React.Component<Props> {
  render () {
    const { logout, redirectToViewTerms } = this.props;
    return (
      <div className="footer">
        <CallButton />
        <FooterButton
          text="Κανόνες Εφαρμογής"
          action={() => redirectToViewTerms()}
          color={Colors.BLUE}
          extraClass={"rules"}
        >
          <FooterIcon iconName={faBookOpen}/>
        </FooterButton>
        <FooterButton
          text="Έξοδος"
          action={() => logout()}
          color={Colors.BRIGHT_ORANGE}
          extraClass={"exit"}
        >
          <FooterIcon iconName={faSignOutAlt}/>
        </FooterButton>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => ({
  logout: (payload) => {
    dispatch(logout(payload))
  },
  redirectToViewTerms: (payload) => {
    dispatch(redirectToViewTerms(payload))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
