const images = {
  LOGO_SQUARE: require('./logoSquare.png'),
  LOGO: require('./logoXamogelo.svg'),
  LOGO_PNG: require('./logo192.png'),
  LOGO_BADGE: require('./logo96.png'),
  ANGRY: require('./feelings/angry.svg'),
  CONFUSED: require('./feelings/confused.svg'),
  DISAPPOINTED: require('./feelings/dissapointd.svg'),
  DONTKNOW: require('./feelings/dontknow.svg'),
  ENERGETIC: require('./feelings/energetic.svg'),
  EXCITED: require('./feelings/excited.svg'),
  FINE: require('./feelings/fine.svg'),
  GOOD: require('./feelings/good.svg'),
  HAPPY: require('./feelings/happy.svg'),
  LONELY: require('./feelings/lonely.svg'),
  NERVOUS: require('./feelings/nervous.svg'),
  RELIEVED: require('./feelings/relieved.svg'),
  SAD: require('./feelings/sad.svg'),
  SCARED: require('./feelings/scared.svg'),
  TIRED: require('./feelings/tired.svg'),
  WORRIED: require('./feelings/worried.svg'),
  DISTURBED: require('./feelings/disturbed.svg'),
  SOS_LINE: require('./chatHomeScreen/sosline.svg'),
  TERMS: require('./chatHomeScreen/terms.svg'),
  WAITING: require('./chatHomeScreen/waiting.svg'),
};

export default images;
