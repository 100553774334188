const messages = {
  STARTS_SOON: {
    message: 'Σύντομα θα ξεκινήσουμε τη συνομιλία μας. Αν έχεις άμεση ανάγκη κάλεσέ μας στη γραμμή SOS 1056',
    className: 'waitingMessageStartsSoon'
  },
  WAITING: {
    message: 'Θα χρειαστεί να περιμένεις μέχρι να ξεκινήσει η συνομιλιά μας. Αν έχεις άμεση ανάγκη κάλεσέ μας στη γραμμή SOS 1056',
    className: 'waitingMessageWaiting'
  },
  NOT_AVAILABLE: {
    message: 'Αυτή τη στιγμή δεν μπορεί να πραγματοποιηθεί συνομιλία καθώς όλοι οι σύμβουλοι είναι απασχολημένοι. Αν έχεις άμεση ανάγκη κάλεσέ μας στη γραμμή SOS 1056',
    className: 'waitingMessageNotAvailable'
  },
};

export default messages;
