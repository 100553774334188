import React from 'react';

type Props = {
  value: string,
  onChange: Function,
  className: string,
  inputName: string,
  textColor: string,
  isFocused: boolean,
  loseFocus: Function,
  giveFocus: Function,
  placeholder?: string,
  focusedClassName: string,
};

const TextInput = (props: Props) => {
  const {
    value, onChange, className, inputName, textColor, isFocused, giveFocus, loseFocus,
    placeholder, focusedClassName,
  } = props;
  return (
    <input
      className={`${className} ${isFocused ? `${ focusedClassName}` : ""}`}
      type="text"
      name={inputName}
      value={value}
      onChange={onChange}
      style={{
        color: textColor,
        fontSize: 16
      }}
      onFocus={giveFocus}
      onBlur={loseFocus}
      placeholder={placeholder || ""}
    />
  );
};

export default TextInput;
