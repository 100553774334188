import React from 'react';
import ThingsToDoScreens from '../../constants/thingsToDoScreens';

type Props = {
  screenSelected: string,
  chooseScreen: Function,
};

const CategoryChooser = (props: Props) => {
  const { screenSelected, chooseScreen } = props;
  return (
    <div className="categoriesChooser">
      <span
        className={`categoryBox ${screenSelected === ThingsToDoScreens.VIDEOS.id ? ' thingSelected' : ''}`}
        id={ThingsToDoScreens.VIDEOS.id}
        onClick={chooseScreen}
      >
        {ThingsToDoScreens.VIDEOS.text}
      </span>
      <span
        className={`categoryBox ${screenSelected === ThingsToDoScreens.ARTICLES.id ? ' thingSelected' : ''}`}
        id={ThingsToDoScreens.ARTICLES.id}
        onClick={chooseScreen}
      >
        {ThingsToDoScreens.ARTICLES.text}
      </span>
    </div>
  );
};

export default CategoryChooser;
