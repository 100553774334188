import { fromJS } from 'immutable';
import * as ActionTypes from '../actions/types';
import { createReducer } from './index';
import { ANONYMOUS, USER } from '../constants/UserGroups';

const INITIAL_STATE = fromJS({
  loading: false,
  id: undefined,
  token: undefined,
  displayName: undefined,
  errorMessage: undefined,
  role: ANONYMOUS,
});

function registerChild(state) {
  return state
    .set('loading', true)
    .set('errorMessage', undefined)
    .set('role', ANONYMOUS)
    .set('result', undefined);
}

function storeChild(state, action) {
  const responseData = action.payload;
  return state
    .set('loading', false)
    .set('token', responseData.text)
    .set('role', USER);
}

function registerChildFailed(state, action) {
  try {
    const responseData = action.payload.body;
    const errors = responseData.errors;
    return state
      .set('loading', false)
      .set('role', ANONYMOUS)
      .set('errorMessage', errors.length ?
        errors[0].message :
        'Κάτι άσχημο συνέβη! Παρακαλώ δοκίμασε ξανα');
  } catch (e) {
    return state
      .set('loading', false)
      .set('role', ANONYMOUS)
      .set('errorMessage', 'Κάτι άσχημο συνέβη! Παρακαλώ δοκίμασε ξανα');
  }
}

function logout() {
  return INITIAL_STATE;
}

export const user = createReducer(INITIAL_STATE, {
  [ActionTypes.REGISTER_CHILD]: registerChild,
  [ActionTypes.REGISTER_CHILD_SUCCESS]: storeChild,
  [ActionTypes.REGISTER_CHILD_FAILED]: registerChildFailed,
  [ActionTypes.LOGOUT]: logout,
});
