import { fromJS } from 'immutable';
import * as ActionTypes from '../actions/types';
import { createReducer } from './index';

const INITIAL_STATE = fromJS({
  isOnline: true,
});

function connected(state) {
  return state.set('isOnline', true);
}

function disconnected(state) {
  return state.set('isOnline', false);
}

export const connectivity = createReducer(INITIAL_STATE, {
  [ActionTypes.CHECK_CONNECTIVITY_SUCCESS]: connected,
  [ActionTypes.CHECK_CONNECTIVITY_FAILED]: disconnected,
});
