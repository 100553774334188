import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../../style/colors';

type Props = {
  iconName: string,
};

const FooterIcon = (props: Props) => {
  const { iconName } = props;
  const ICON_SIZE = 20;
  const iconStyle = {
    width: ICON_SIZE,
    height: ICON_SIZE,
  };
  return (
    <FontAwesomeIcon
      className="footerButtonIcon"
      icon={iconName}
      color={Colors.WHITE}
      style={iconStyle}
    />
  );
};

export default FooterIcon;
