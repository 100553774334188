import React from 'react';
import './chatHomeScreen.css';
import StartChatBox from './startChatBox';
import ThingToDo from './thingToDo';
import Images from '../../assets/images';

type Props = {
  history: Object,
  handleChange: Function,
  chatSubject: string,
  storeChatSubject: Function,
};

const ChatHomeScreen = (props: Props) => {
  const { history, handleChange, chatSubject, storeChatSubject } = props;
  return (
    <div className="chatHomeScreen">
      <h1>Καλώς όρισες</h1>
      <div className="chatStartBoxAfterHeaderContainer">
      <StartChatBox
        history={history}
        handleChange={handleChange}
        chatSubject={chatSubject}
        storeChatSubject={storeChatSubject}
      />
      </div>
      <div className="thingsToDoContainer">
        <ThingToDo
          pictureUri={Images.SOS_LINE}
          title={"Τι είναι η γραμμή SOS 1056?"}
          action={() => history.push('/sosLine')}
        />
        <ThingToDo
          pictureUri={Images.TERMS}
          title={"Κανόνες Λειτουργίας"}
          action={() => history.push('/viewTerms')}
        />
        <ThingToDo
          pictureUri={Images.WAITING}
          title={"Κάτι για να περιμένω"}
          action={() => history.push('/thingsToDo')}
        />
      </div>
    </div>
  );
};

export default ChatHomeScreen;
