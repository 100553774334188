import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../../style/colors';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';

const TermsHeader = () => {
  return (
    <div className="termsHeader">
      <span className="termsHeaderText">
        Κανόνες λειτουργίας
      </span>
      <FontAwesomeIcon
        className="termsHeaderIcon"
        icon={faBookOpen}
        color={Colors.WHITE}
        size={"2x"}
      />
    </div>
  );
};

export default TermsHeader;
