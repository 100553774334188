import React from 'react';
import Button from '../general/button/button';

type Props = {
  text: string,
  action: Function,
  color: string,
  children: any,
  extraClass: string,
};

const FooterButton = (props: Props) => {
  const { text, action, color, children, extraClass } = props;
  return (
      <Button
        color={color}
        className={`footer-${extraClass} grow "`}
        onClick={action}
      >
        {children}
        <span className="bold">
          {text}
        </span>
    </Button>
  );
};

export default FooterButton;
