import {combineSignalREvents} from "../store/signalRMiddleware";
import * as ActionTypes from "../actions/types"

const chatStarted = {
  eventName: 'ChatStarted',
  actionName: ActionTypes.SIGNAL_R_EVENT_CHAT_STARTED,
};

const chatEnded = {
  eventName: 'ChatEnded',
  actionName: ActionTypes.SIGNAL_R_EVENT_CHAT_ENDED,
};

const messageSent = {
  eventName: 'MessageSent',
  actionName: ActionTypes.SIGNAL_R_EVENT_CHAT_NEW_MESSAGE,
};

const childStartedChat = {
  eventName: 'ChatInitiated',
  actionName: ActionTypes.CHILD_STARTED_CHAT,
};

const signalREventHandlers = combineSignalREvents(chatStarted,chatEnded,messageSent, childStartedChat);

export default signalREventHandlers;
