import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from "history";
import Immutable from "immutable";
import {createLogger} from 'redux-logger';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/es/storage' // default: localStorage if web, AsyncStorage if react-native
import immutableTransform from 'redux-persist-transform-immutable';
import { run } from '@cycle/run';
import { makeHTTPDriver } from '@cycle/http';
import { createCycleMiddleware } from 'redux-cycles';
import { routerMiddleware } from 'react-router-redux';
import reducers from '../reducers';
import main from '../cycles/cycles';
import { createSignalRMiddleware } from './signalRMiddleware';
import signalREventHandlers from '../signalRs';
import { SIGNAL_R_URL } from '../config';

const middleWare = [];

const logger = createLogger({
  stateTransformer: (state) => {
    let newState = {};

    for (var i of Object.keys(state)) {
      if (Immutable.Iterable.isIterable(state[i])) {
        newState[i] = state[i].toJS();
      } else {
        newState[i] = state[i];
      }
    };

    return newState;
  }
});


export const history = createBrowserHistory();
middleWare.push(routerMiddleware(history));

const config = {
  transforms: [immutableTransform({
    whitelist: ['user', 'chat', 'pages', 'terms']
  })],
  key: 'rootHamogeloChat',
  whitelist: ['user','chat','pages', 'terms'],
  storage,
};

const cycleMiddleware = createCycleMiddleware();
const signalRMiddleware = createSignalRMiddleware({
  url: SIGNAL_R_URL,
  signalREventHandlers,
  logMessageContent: true,
  logger: 'Trace'
});
const { makeActionDriver, makeStateDriver } = cycleMiddleware;

middleWare.push(cycleMiddleware);
middleWare.push(signalRMiddleware);

if (process.env.NODE_ENV === 'development') {
  middleWare.push(logger);
}

const store = createStore(
  persistReducer(config,reducers),
  compose(applyMiddleware(...middleWare)),
);

export const persistor = persistStore(store);

run(main, {
  ACTION: makeActionDriver(),
  STATE: makeStateDriver(),
  HTTP: makeHTTPDriver(),
});

export default store;
