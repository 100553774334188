const colors = {
  ALL_SCREENS_BACKGROUND: '#FFFFFF',
  WHITE : '#FFFFFF',
  BLUE: '#008ECB',
  BLACK: 'black',
  ORANGE: '#DB6727',
  BRIGHT_ORANGE: '#FC7608',
  PALE_ORANGE: '#E5AD4D',
  YELLOW: '#F4D80D',
  GREY: '#606C73',
  RED: 'red',
  LIGHT_GREY: '#BDBDBD'
};

export default colors;
