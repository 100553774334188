import { fromJS } from 'immutable';
import * as ActionTypes from '../actions/types';
import { createReducer } from './index';
import moment from 'moment';

const INITIAL_STATE = fromJS({
  loading: false,
  id: undefined,
  messages: [],
  endTime: undefined,
  subject: undefined,
  activeAgents: undefined,
});

function startChat(state) {
  return state.set('loading', true);
}

function startChatSuccess(state, action) {
  return state
    .set('loading', false)
    .set('id', action.payload.body);
}

function startChatFailed(state, action) {
  return state.set('loading', false);
}

function storeMessages(state, action) {
  try {
    const responseData = action.payload.body.results;
    const messages = responseData.reverse().map(messageItem => ({
      body: messageItem.body,
      created: messageItem.createdDate,
      id: messageItem.id,
      isMine: !messageItem.isAgent
    }));
    return state.set('messages', fromJS(messages))
  } catch (e) {
    return state;
  }
}

function sendMessage(state, action) {
  return state.updateIn(['messages'], arr => arr.push(fromJS({
    body: action.payload,
    created: moment().utc().format('YYYY-MM-DDThh:mm:ss'),
    id: undefined,
    isMine: true,
    sending: true,
  })))
}

function sendMessageSuccess(state, action) {
  try {
    const messageId = action.payload.body;
    const stateMessages = state.get('messages');
    return state
      .set('messages', stateMessages.setIn([stateMessages.size -1, 'sending'], false))
      .set('messages', stateMessages.setIn([stateMessages.size -1, 'id'], messageId));
  } catch (e) {
    return state;
  }
}

function storeChatInfo(state, action) {
  try {
    const endTime = action.payload.body.result.expiryTimestamp;
    const momentEndTime = moment.utc(endTime, 'YYYY-MM-DD HH:mm:ss.SS').unix().valueOf();
    return state.set('endTime', momentEndTime)
  } catch (e) {
    console.log('error on parsing to moment');
    console.log(e);
    return state;
  }
}

// TODO: What happens on fail
function getChatInfoFailed(state, action) {
  return state;
}

function storeChatSubject(state, action) {
  return state.set('subject', action.payload);
}

function childStartedChat(state, action) {
  try {
    return state.set('activeAgents', action.payload.ChatId);
  } catch (e) {
    return state;
  }
}

function chatEnded(state, action) {
  return state.set('endTime', undefined);
}

function logout() {
  return INITIAL_STATE;
}

export const chat = createReducer(INITIAL_STATE, {
  [ActionTypes.START_CHAT]: startChat,
  [ActionTypes.START_CHAT_SUCCESS]: startChatSuccess,
  [ActionTypes.START_CHAT_FAILED]: startChatFailed,
  [ActionTypes.GET_MESSAGES_SUCCESS]: storeMessages,
  [ActionTypes.SEND_MESSAGE]: sendMessage,
  [ActionTypes.SEND_MESSAGE_SUCCESS]: sendMessageSuccess,
  [ActionTypes.GET_CHAT_INFO_SUCCESS]: storeChatInfo,
  [ActionTypes.GET_CHAT_INFO_FAILED]: getChatInfoFailed,
  [ActionTypes.STORE_CHAT_SUBJECT]: storeChatSubject,
  [ActionTypes.CHILD_STARTED_CHAT]: childStartedChat,
  [ActionTypes.SIGNAL_R_EVENT_CHAT_ENDED]: chatEnded,
  [ActionTypes.LOGOUT]: logout,
});
