const getYoutubeVideoIdFromUrl = (url) => {
  if (!url || !url.length) return undefined;
  let videoId = url.split('v=')[1];

  let ampersandPosition = videoId.indexOf('&');
  if(ampersandPosition !== -1) {
    videoId = videoId.substring(0, ampersandPosition);
    return videoId;
  }
  return videoId;
};

export default getYoutubeVideoIdFromUrl;
