import React from 'react';
import Images from '../../assets/images';

const HeaderTitle = () => {
  return (
    <div className="headerTitle textAlignCenter">
     <img
       src={Images.LOGO_SQUARE}
       alt={"CHAT 1056"}
       style={{
         width: 70
       }}
     />
    </div>
  );
};

export default HeaderTitle;
