import { combineCycles } from 'redux-cycles';
import xs from 'xstream';
import * as actions from '../actions'
import * as ActionTypes from '../actions/types';
import { BASE_URL} from '../config';
import {postHeaderWithTokenJson} from './headers';
import sampleCombine from 'xstream/extra/sampleCombine';

export function acceptTerms(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.get('token'));

  const request$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ACCEPT_TERMS)
    .compose(sampleCombine(token$))
    .map(([action, token]) => ({
      url: BASE_URL + `ApplicationUsers/child/AcceptTermsOfUse`,
      category: 'acceptTerms',
      method: 'PATCH',
      headers: postHeaderWithTokenJson(token),
      send: {
        acceptTermsOfUse: true,
      }
    }));

  let httpResponse$ = sources.HTTP
    .select('acceptTerms')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 204)
    .map(response => actions.acceptTermsSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  }
}

export function acceptTermsFailed(sources) {
  const response$ = sources.HTTP
    .select('acceptTerms')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 204);

  const action$ = xs.combine(response$)
    .map(response => actions.acceptTermsFailed(response));

  return {
    ACTION: action$,
  };
}

export function getTerms(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.get('token'));

  const request$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_TERMS)
    .compose(sampleCombine(token$))
    .map(([action, token]) => ({
      url: BASE_URL + 'pages/TermsOfUse',
      category: 'getTerms',
      headers: postHeaderWithTokenJson(token),
      method: 'GET',
    }));

  let httpResponse$ = sources.HTTP
    .select('getTerms')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map(response => actions.getTermsSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  }
}

export function getTermsFailed(sources) {
  const response$ = sources.HTTP
    .select('getTerms')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 200);

  const action$ = xs.combine(response$)
    .map(response => actions.getTermsFailed(response));

  return {
    ACTION: action$,
  };
}

export default combineCycles(
  getTerms,
  getTermsFailed,
  acceptTerms,
  acceptTermsFailed,
);
