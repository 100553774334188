// @flow
import React from 'react';
import { connect } from 'react-redux';
import Unauthorized from '../components/unauthorized/unauthorized';
import CheckOnlineHOC from './CheckOnlineHOC';

type Props = {
  role: string
};

const AuthorizationHOC = (allowedRoles: Array<string>) =>
  (WrappedComponent: any) => {
    class WithAuthorization extends React.Component<Props> {
      render () {
        const { role } = this.props;
        if (allowedRoles.includes(role)) {
          return (
            <WrappedComponent
              {...this.props}
              role={role}
            />);
        } else {
          return (
            <Unauthorized />
          )
        }
      }
    }

    const mapStateToProps = (state) => {
      return {
        role: state.user.get('role')
      }
    };

    const mapDispatchToProps = () => {
      return {
      }
    };

    return connect(mapStateToProps, mapDispatchToProps)(CheckOnlineHOC(WithAuthorization));

  };

export default AuthorizationHOC;
