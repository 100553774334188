import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../../style/colors';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import TextInput from '../general/textInput/textInput';
import Button from '../general/button/button';

type Props = {
  history: Object,
  handleChange: Function,
  chatSubject: string,
  storeChatSubject: Function,
};

const StartChatBox = (props: Props) => {
  const { history, handleChange, chatSubject, storeChatSubject } = props;

  const submit = () => {
    if (chatSubject.trim().length) {
      storeChatSubject(chatSubject);
      setTimeout(() => {
        history.push('/feelingBeforeChat');
      }, 1);
    }
  };

  return (
    <div
      className="startChatBoxContainer"
    >
      <div onClick={submit}>
      <FontAwesomeIcon
        icon={faComments}
        size="6x"
        color={Colors.BLUE}
      />
      </div>
        <TextInput
          value={chatSubject}
          onChange={handleChange}
          className="chatSubjectTextInput"
          inputName="chatSubject"
          textColor={Colors.BLUE}
          isFocused={false}
          loseFocus={undefined}
          giveFocus={undefined}
          focusedClassName={""}
          placeholder={"Θέμα συνομιλίας;"}
          />
      <div className="button-start-chat-container">
      <Button
        className="start-chat"
        color={Colors.BLUE}
        onClick={() => submit()}
        >
        <span
          style={{
          color: Colors.WHITE,
        }}>
          Κάνε Chat
        </span>
      </Button>
      </div>
    </div>
  );
};

export default StartChatBox;
