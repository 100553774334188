// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import FeelingsScreen from '../components/feelings/feelingsScreen';
import { startChat } from '../actions';
import FEELINGS from '../constants/feelings';

type Props = {
  startChat: Function,
  history: Object,
};

type State = {
  feelingSelected?: string,
}

class FeelingsHOC extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      feelingSelected: undefined
    }
  }

  setFeelingSelected = (event) => {
    this.setState({
      feelingSelected: event.currentTarget.id
    });

      const feelingSelected = FEELINGS
        .filter(feeling => feeling.id === event.currentTarget.id)[0];
      this.props.startChat({
        feeling: feelingSelected.feeling
      });
      this.props.history.replace('/thingsToDo');
  };

  render() {
    return (
      <FeelingsScreen
        feelingSelected={this.state.feelingSelected}
        setFeelingSelected={this.setFeelingSelected}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  startChat: (payload) => {
    dispatch(startChat(payload))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FeelingsHOC);
