import { combineReducers } from 'redux';
import { user } from './user';
import { terms } from './terms';
import { chat } from './chat';
import { connectivity } from './connectivity';
import { pages } from './pages';
import { thingsToDo } from './thingsToDo';
import { sosLine } from './sosLine';
import { general } from './general';

const reducers = combineReducers({
  user,
  terms,
  chat,
  connectivity,
  pages,
  thingsToDo,
  sosLine,
  general,
});

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}

export default reducers;
