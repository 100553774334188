import Images from '../assets/images';

const FEELINGS = [
  {
    feeling: "Happy",
    feelingGr: "Χαρούμενος",
    id: "1",
    image: Images.HAPPY,
  },{
    feeling: "Great",
    feelingGr: "Εξαιρετικά",
    id: "2",
    image: Images.FINE,
  },{
    feeling: "Good",
    feelingGr: "Καλά",
    id: "3",
    image: Images.GOOD,
  },{
    feeling: "VeryExcited",
    feelingGr: "Ενθουσιασμένος",
    id: "4",
    image: Images.EXCITED,
  },{
    feeling: "Sad",
    feelingGr: "Λυπημένος",
    id: "5",
    image: Images.SAD,
  },{
    feeling: "Energetic",
    feelingGr: "Δυναμικός",
    id: "6",
    image: Images.ENERGETIC,
  },{
    feeling: "DontKnow",
    feelingGr: "Δεν ξέρω",
    id: "7",
    image: Images.DONTKNOW,
  },{
    feeling: "Alone",
    feelingGr: "Μόνος",
    id: "8",
    image: Images.LONELY,
  },{
    feeling: "Disappointed",
    feelingGr: "Απογοητευμένος",
    id: "9",
    image: Images.DISAPPOINTED,
  },{
    feeling: "Annoyed",
    feelingGr: "Ενοχλημένος",
    id: "10",
    image: Images.DISTURBED,
  },{
    feeling: "Angry",
    feelingGr: "Θυμωμένος",
    id: "11",
    image: Images.ANGRY,
  },{
    feeling: "Scared",
    feelingGr: "Φοβισμένος",
    id: "12",
    image: Images.SCARED,
  },{
    feeling: "Worried",
    feelingGr: "Ανήσυχος",
    id: "13",
    image: Images.WORRIED,
  },{
    feeling: "Anxious",
    feelingGr: "Αγχωμένος",
    id: "14",
    image: Images.NERVOUS,
  },{
    feeling: "Relieved",
    feelingGr: "Ανακουφισμένος",
    id: "15",
    image: Images.RELIEVED,
  },{
    feeling: "Tired",
    feelingGr: "Κουρασμένος",
    id: "16",
    image: Images.TIRED,
  },{
    feeling: "Confused",
    feelingGr: "Μπερδεμένος",
    id: "17",
    image: Images.CONFUSED,
  },
];

export default FEELINGS;
