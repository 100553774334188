import { fromJS } from 'immutable';
import * as ActionTypes from '../actions/types';
import { createReducer } from './index';

const INITIAL_STATE = fromJS({
  loading: false,
  title: "",
  body: ""
});

function getSosLinePages(state) {
  return state.set('loading', true);
}
function getSosLinePagesSuccess(state, action) {
  return state
    .set('title', action.payload.body.result.title)
    .set('body', action.payload.body.result.body)
    .set('loading', false);
}

function getSosLinePagesFailed(state) {
  return state.set('loading', false);
}

export const sosLine = createReducer(INITIAL_STATE, {
  [ActionTypes.GET_SOS_LINE_PAGES]: getSosLinePages,
  [ActionTypes.GET_SOS_LINE_PAGES_SUCCESS]: getSosLinePagesSuccess,
  [ActionTypes.GET_SOS_LINE_PAGES_FAILED]: getSosLinePagesFailed,
});
