import { combineCycles } from 'redux-cycles';
import xs from 'xstream';
import * as actions from '../actions'
import * as ActionTypes from '../actions/types';
import { BASE_URL} from '../config';
import { postHeaderWithTokenJson } from './headers';
import sampleCombine from 'xstream/extra/sampleCombine';

export function startChat(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.get('token'));
  const subject$ = state$.map(state => state.chat.get('subject'));

  const request$ = sources.ACTION
    .filter(action => action.type === ActionTypes.START_CHAT)
    .compose(sampleCombine(token$, subject$))
    .map(([action, token, subject]) => ({
      url: BASE_URL + 'chats',
      category: 'startChat',
      method: 'POST',
      headers: postHeaderWithTokenJson(token),
      send: {
        subject,
        "startFeeling": action.payload.feeling,
      }
    }));

  let httpResponse$ = sources.HTTP
    .select('startChat')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 201)
    .map(response => actions.startChatSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  }
}

export function startChatFailed(sources) {
  const response$ = sources.HTTP
    .select('startChat')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 201);

  const action$ = xs.combine(response$)
    .map(response => actions.startChatFailed(response));

  return {
    ACTION: action$,
  };
}

export function sendMessage(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.get('token'));
  const chatId$ = state$.map(state => state.chat.get('id'));

  const request$ = sources.ACTION
    .filter(action => action.type === ActionTypes.SEND_MESSAGE)
    .compose(sampleCombine(token$, chatId$))
    .map(([action, token, chatId]) => ({
      url: BASE_URL + 'messages',
      category: 'sendMessage',
      method: 'POST',
      headers: postHeaderWithTokenJson(token),
      send: {
        body: action.payload,
        chatId,
      }
    }));

  let httpResponse$ = sources.HTTP
    .select('sendMessage')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 201)
    .map(response => actions.sendMessageSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  }
}

export function sendMessageFailed(sources) {
  const response$ = sources.HTTP
    .select('sendMessage')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 201);

  const action$ = xs.combine(response$)
    .map(response => actions.sendMessageFailed(response));

  return {
    ACTION: action$,
  };
}

export function getAllMessagesWhenSignalRNotificationComes(sources) {
    const action$ = sources.ACTION
      .filter(action => action.type === ActionTypes.SIGNAL_R_EVENT_CHAT_NEW_MESSAGE)
      .map(() => actions.getMessages());

    return {
      ACTION: action$,
    }
}

export function getMessages(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.get('token'));
  const chatId$ = state$.map(state => state.chat.get('id'));

  const request$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_MESSAGES)
    .compose(sampleCombine(token$, chatId$))
    .map(([action, token, chatId]) => ({
      url: BASE_URL + `messages/child?ChatId=${chatId}`,
      category: 'getMessages',
      method: 'GET',
      headers: postHeaderWithTokenJson(token),
    }));

  let httpResponse$ = sources.HTTP
    .select('getMessages')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map(response => actions.getMessagesSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  }
}

export function getMessagesFailed(sources) {
  const response$ = sources.HTTP
    .select('getMessages')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 200);

  const action$ = xs.combine(response$)
    .map(response => actions.getMessagesFailed(response));

  return {
    ACTION: action$,
  };
}

export function getMessagesAfterSentMessageSuccess(sources) {
  const response$ = sources.HTTP
    .select('sendMessage')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status === 201);

  const action$ = xs.combine(response$)
    .map(response => actions.getMessages());

  return {
    ACTION: action$,
  };
}

export function getChatInfoAfterSignalREvent(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.get('token'));
  const chatId$ = state$.map(state => state.chat.get('id'));

  const request$ = sources.ACTION
    .filter(action => action.type === ActionTypes.SIGNAL_R_EVENT_CHAT_STARTED)
    .compose(sampleCombine(token$, chatId$))
    .map(([action, token, chatId]) => ({
      url: BASE_URL + `chats/${chatId}`,
      category: 'getChatInfoAfterSignalREvent',
      method: 'GET',
      headers: postHeaderWithTokenJson(token),
    }));

  let httpResponse$ = sources.HTTP
    .select('getChatInfoAfterSignalREvent')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map(response => actions.getChatInfoSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  }
}

export function getChatInfoAfterSignalREventFailed(sources) {
  const response$ = sources.HTTP
    .select('getChatInfoAfterSignalREvent')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 200);

  const action$ = xs.combine(response$)
    .map(response => actions.getChatInfoFailed(response));

  return {
    ACTION: action$,
  };
}

export function sendEndFeeling(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.get('token'));
  const chatId$ = state$.map(state => state.chat.get('id'));

  const request$ = sources.ACTION
    .filter(action => action.type === ActionTypes.SEND_END_FEELING)
    .compose(sampleCombine(token$, chatId$))
    .map(([action, token, chatId]) => ({
      url: BASE_URL + `chats/${chatId}/sendEndFeeling`,
      category: 'sendEndFeeling',
      method: 'PATCH',
      headers: postHeaderWithTokenJson(token),
      send: {
        "endFeeling": action.payload.feeling,
      }
    }));

  let httpResponse$ = sources.HTTP
    .select('sendEndFeeling')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 204)
    .map(response => actions.sendEndFeelingSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  }
}

export function sendEndFeelingFailed(sources) {
  const response$ = sources.HTTP
    .select('sendEndFeeling')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 204);

  const action$ = xs.combine(response$)
    .map(response => actions.sendEndFeelingFailed(response));

  return {
    ACTION: action$,
  };
}

export default combineCycles(
  startChat,
  startChatFailed,
  sendMessage,
  sendMessageFailed,
  getMessages,
  getMessagesFailed,
  getAllMessagesWhenSignalRNotificationComes,
  getChatInfoAfterSignalREvent,
  getChatInfoAfterSignalREventFailed,
  getMessagesAfterSentMessageSuccess,
  sendEndFeeling,
  sendEndFeelingFailed
);
