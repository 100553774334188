import { combineCycles } from 'redux-cycles';
import xs from 'xstream';
import { push, replace } from 'react-router-redux';
import * as ActionTypes from '../actions/types';

export function redirectAfterSuccessfulLogin(sources) {
  let httpResponse$ = sources.HTTP
    .select('registerChild')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 201)
    .map(() => replace('/terms'));
  return {
    ACTION: httpResponse$
  }
}

export function redirectAfterAgreeingToTerms(sources) {
  let httpResponse$ = sources.HTTP
    .select('acceptTerms')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 204)
    .map(() => push('/chatHomeScreen'));
  return {
    ACTION: httpResponse$
  }
}

export function redirectWhenChatStarts(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.SIGNAL_R_EVENT_CHAT_STARTED)
    .map(() => push('/chat'));

  return {
    ACTION: action$,
  }
}

export function redirectWhenChatEnds(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.SIGNAL_R_EVENT_CHAT_ENDED)
    .map(() => push('/feelingAfterChat'));

  return {
    ACTION: action$,
  }
}

export function redirectOnLogout(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.LOGOUT)
    .map(() => push('/'));

  return {
    ACTION: action$,
  }
}

export function redirectToViewTerms(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.REDIRECT_TO_VIEW_TERMS)
    .map(() => push('/viewTerms'));

  return {
    ACTION: action$,
  }
}

export default combineCycles(
  redirectAfterSuccessfulLogin,
  redirectAfterAgreeingToTerms,
  redirectWhenChatStarts,
  redirectOnLogout,
  redirectToViewTerms,
  redirectWhenChatEnds
);
