import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import Colors from '../../style/colors';

type Props = {
  timeRemaining: Object,
};

const ChatHeaderTimeRemaining = (props: Props) => {
  const { timeRemaining } = props;
  return (
    <div className="chatHeaderTimeRemaining">
      <div className="clock">
        <FontAwesomeIcon
          className="clockIcon"
          icon={faClock}
          size="1x"
          color={Colors.BLUE} />
        <span className="timeLeft bold">
              {timeRemaining ? timeRemaining.minutes + ':' + timeRemaining.seconds : ''}
            </span>
      </div>
      <span>
          ο χρόνος συζήτησης που απομένει
        </span>
    </div>
  );
};

export default ChatHeaderTimeRemaining;
