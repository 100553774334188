import * as ActionTypes from './types';
import { actionCreator } from '../helpers/actionCreator';

export const acceptTerms = actionCreator(ActionTypes.ACCEPT_TERMS);
export const acceptTermsFailed = actionCreator(ActionTypes.ACCEPT_TERMS_FAILED);
export const acceptTermsSuccess = actionCreator(ActionTypes.ACCEPT_TERMS_SUCCESS);
export const getTerms = actionCreator(ActionTypes.GET_TERMS);
export const getTermsFailed = actionCreator(ActionTypes.GET_TERMS_FAILED);
export const getTermsSuccess = actionCreator(ActionTypes.GET_TERMS_SUCCESS);

