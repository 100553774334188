import moment from 'moment';

const parseMessages = (messages) => {
  if (!messages.size) return [];

  return messages.valueSeq().toArray().map(
    (message, index) => ({
      id: message.get('id'),
      data: {
        message: message.get('body'),
        timestamp: moment.utc(message.get('created')).local().format('HH:mm'),
      },
      isMine: message.get('isMine'),
      startsSequence: index === 0,
      endsSequence: index === messages.size - 1,
      showTimestamp: true,
      sendingInProgress: message.get('sending')
    })
  );
};

export default parseMessages;
