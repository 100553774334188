const getArticle = (articles, articleId) => {
  const result = articles
    .filter(articleItem =>
      articleItem.get('id') === articleId);

  if (result.size) {
    const theArticle = result.valueSeq().toArray()[0];
    return {
      id: theArticle.get('id'),
      body: theArticle.get('body'),
      imageEncodedFile: theArticle.get('imageEncodedFile'),
      title: theArticle.get('title'),
      url: theArticle.get('url'),
    };
  }
  return undefined;
};

export default getArticle;
