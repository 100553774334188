// @flow
import React from 'react';
import { connect } from 'react-redux';
import { checkConnectivity, setUserAgentInfo } from '../actions';
import Bowser from "bowser";

type Props = {
  checkConnectivity: Function,
  isOnline: boolean,
  setUserAgentInfo: Function,
};

const CheckOnlineHOC = (WrappedComponent: any) => {
    class CheckOnline extends React.Component<Props> {
      onlineHandle: IntervalID;
      componentDidMount() {
        this.props.checkConnectivity();
        this.props.setUserAgentInfo(Bowser.parse(window.navigator.userAgent));
        this.onlineHandle = setInterval(this.props.checkConnectivity, 10000);
      }

      componentWillUnmount() {
        clearInterval(this.onlineHandle);
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            isOnline={this.props.isOnline}
          />);
      }
    }

    const mapStateToProps = (state) => {
      return {
        isOnline: state.connectivity.get('isOnline')
      }
    };

    const mapDispatchToProps = dispatch => ({
      checkConnectivity: (payload) => {
        dispatch(checkConnectivity(payload))
      },
      setUserAgentInfo: (payload) => {
        dispatch(setUserAgentInfo(payload))
      }
    });

    return connect(mapStateToProps, mapDispatchToProps)(CheckOnline)
  };

export default CheckOnlineHOC;
