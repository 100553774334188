import React from 'react';
import TermsHeader from './termsHeader';
import './terms.css';
import TitleValueCard from '../general/cards/titleValueCard';
import Terms from './terms';
import AcceptTermsSticky from './acceptTermsSticky';
import LoadingScreen from '../general/loadingScreen/loadingScreen';

type Props = {
  loading: boolean,
  checked: boolean,
  handleCheckboxChange: Function,
  title: string,
  body: string,
};

const TermsScreen = (props: Props) => {
  const { loading, checked, handleCheckboxChange, title, body } = props;

  if (loading) {
    return <LoadingScreen/>;
  }

  return (
    <div>
      <TermsHeader/>
      <div className="termsContent">
        <TitleValueCard
          title={title}
          value=""
        />
        <div className="termsContainer">
        <Terms
          body={body}
        />
        </div>
        <AcceptTermsSticky
          checked={checked}
          handleCheckboxChange={handleCheckboxChange}
        />
      </div>
    </div>
  );
};

export default TermsScreen;
