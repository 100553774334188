import React from 'react';
import './LoginScreen.css';
import TextInput from '../general/textInput/textInput';
import Colors from '../../style/colors';
import AgeCircle from './ageCircle';
import Button from '../general/button/button';
import AGES from '../../constants/ages';
import LoadingScreen from '../general/loadingScreen/loadingScreen';

type Props = {
  nickname: string,
  onNicknameChange: Function,
  onAgeChange: Function,
  ageSelected: string,
  isAgeFocused: boolean,
  giveFocusToAge: Function,
  loseFocus: Function,
  history: Object,
  registerChild: Function,
  loading: boolean,
  errorMessage: string,
  setValidationErrorMessage: Function,
  validationErrorMessage?: string,
};

const LoginScreen = (props: Props) => {
  const {
    nickname, onNicknameChange, onAgeChange, ageSelected, isAgeFocused,
    giveFocusToAge, loseFocus, registerChild, loading, errorMessage,
    setValidationErrorMessage, validationErrorMessage,
  } = props;

  if (loading) {
    return <LoadingScreen />
  }
  return (
    <div className="loginScreen">
        <h1>Εγγραφή</h1>
      {errorMessage ? <h3 className="errorMessage">{errorMessage}</h3> : null}
      {validationErrorMessage ? <h3 className="errorMessage">{validationErrorMessage}</h3> : null}
      <div>
        <div className="loginForm">
          <h2 className={isAgeFocused ? "focusedTitle" : ""}>Ψευδώνυμο</h2>
          <TextInput
            value={nickname}
            onChange={onNicknameChange}
            className="loginScreenTextInput"
            inputName="nickname"
            textColor={Colors.BLUE}
            isFocused={isAgeFocused}
            giveFocus={giveFocusToAge}
            loseFocus={loseFocus}
            focusedClassName="focusedInput"
          />
          <h2>Ηλικία</h2>
          <div className="circlesContainer">
            {AGES.map((item, index) => (
              <AgeCircle
                age={item.age}
                ageTitle={item.title}
                id={index}
                key={index}
                onClick={onAgeChange}
                ageSelected={ageSelected}
              />
            ))
            }
          </div>
        </div>
        <div className="button-login-container">
          <div style={{
            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            backgroundColor: Colors.RED,
            padding: 10,
            borderRadius: 10,
          }}>
            <span style={{
              color: Colors.WHITE,
              alignSelf: 'center'
            }}>
                Στο Chat 1056 μπορείς να μιλήσεις για τα συναισθήματα σου κι ότι σε απασχολεί. Αν έχεις όμως άμεση ανάγκη κάλεσε μας στη Γραμμή 1056
             </span>
          </div>
          <Button
            className="login"
            color={Colors.BLUE}
            onClick={() => {
              if (nickname.trim().length && ageSelected) {
                setValidationErrorMessage(undefined);
                registerChild({
                  displayName: nickname,
                  age: ageSelected
                });
              } else {
                setValidationErrorMessage('Πρέπει να συμπληρώσεις ψευδώνυμο και ηλικία');
              }
            }}
          >
          <span style={{
            color: Colors.WHITE,
          }}>
            Είσοδος
          </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
