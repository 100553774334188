import { combineCycles } from 'redux-cycles';
import xs from 'xstream';
import * as actions from '../actions'
import * as ActionTypes from '../actions/types';
import { BASE_URL} from '../config';
import {postHeaderWithTokenJson} from './headers';
import sampleCombine from 'xstream/extra/sampleCombine';

export function getPages(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.get('token'));

  const request$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_PAGES)
    .compose(sampleCombine(token$))
    .map(([action, token]) => {
      const url =
        action.payload && action.payload.page
          ? `pages?page=${action.payload.page}`
          : 'pages';
      return {
        url: BASE_URL + url,
        category: 'getPages',
        method: 'GET',
        headers: postHeaderWithTokenJson(token),
      }
    });

  let httpResponse$ = sources.HTTP
    .select('getPages')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map(response => actions.getPagesSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  }
}

export function getPagesFailed(sources) {
  const response$ = sources.HTTP
    .select('getPages')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 200);

  const action$ = xs.combine(response$)
    .map(response => actions.getPagesFailed(response));

  return {
    ACTION: action$,
  };
}

export function getNextPageOfPagesIfThereIsSuch(sources) {
  const response$ = sources.HTTP.select('getPages')
    .map(response => response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$).map(response => {
    try {
      if (response[0].body.totalPages !== response[0].body.page) {
        return actions.getPages({page: response[0].body.page + 1});
      }
      return actions.nullAction();
    } catch (e) {
      return actions.nullAction();
    }
  });

  return {
    ACTION: action$,
  };
}

//
// export function getPagesAfterLoggingInSuccessfully(sources) {
//   const request$ = sources.ACTION
//     .filter(action => action.type === ActionTypes.REGISTER_CHILD_SUCCESS)
//     .map(action => ({
//       url: BASE_URL + 'pages',
//       category: 'getPagesAfterLoggingInSuccessfully',
//       method: 'GET',
//       headers: postHeaderWithTokenJson(action.payload.text),
//     }));
//
//   let httpResponse$ = sources.HTTP
//     .select('getPagesAfterLoggingInSuccessfully')
//     .map((response) =>
//       response.replaceError((err) => xs.of(err))
//     )
//     .flatten()
//     .filter(response => response.status === 200)
//     .map(response => actions.getPagesSuccess(response));
//
//   return {
//     ACTION: httpResponse$,
//     HTTP: request$
//   }
// }
//
// export function getPagesAfterLoggingInSuccessfullyFailed(sources) {
//   const response$ = sources.HTTP
//     .select('getPagesAfterLoggingInSuccessfully')
//     .map(response =>
//       response.replaceError(err => xs.of(err)))
//     .flatten()
//     .filter(response => response.status !== 200);
//
//   const action$ = xs.combine(response$)
//     .map(response => actions.getPagesFailed(response));
//
//   return {
//     ACTION: action$,
//   };
// }

export default combineCycles(
  getPages,
  getPagesFailed,
  // getPagesAfterLoggingInSuccessfully,
  // getPagesAfterLoggingInSuccessfullyFailed,
  getNextPageOfPagesIfThereIsSuch
);
