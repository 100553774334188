import React from 'react';
import Colors from '../../style/colors';
import './headerIcon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  iconName: string,
  history: object,
  goBack: boolean,
};

const HeaderIcon = (props: Props) => {
  const { iconName, history, goBack } = props;
  const SIZE = 25;
  const ICON_STYLE = {
    width: SIZE,
    height: SIZE,
  };
  return (
    <div
      className="headerIcon"
      onClick={() => {
        if(goBack){
          history.goBack()
        }
      }}
    >
      <FontAwesomeIcon
        className=""
        icon={iconName}
        color={Colors.BLACK}
        style={ICON_STYLE}
      />
    </div>
  );
};

export default HeaderIcon;
