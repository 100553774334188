import React from 'react';

type Props = {
  feelingGR: string,
  id: string,
  startChat: Function,
  feelingSelected: string,
  setFeelingSelected: Function,
  image: string,
};

const Feeling = (props: Props) => {
  const { feelingGR, id, setFeelingSelected, feelingSelected, image } = props;
  return (
    <div
      className="feeling"
      id={id}
      onClick={setFeelingSelected}
    >
      <span
        className={`bold + ${feelingSelected === id ? ' feelingSelected' : ''}`}>
      {feelingGR}
      </span>
    <span className="feelingPictureContainer">
      <img src={image} alt={feelingGR} className="feelingPicture" />
    </span>
    </div>
  );
};

export default Feeling;
