// EXAMPLE for set up:
//
//store.js:
// const signalRMiddleware = createSignalRMiddleware({
//     url: SIGNAL_R_URL,
//     signalREventHandlers,
//     logMessageContent: true,
//     logger: 'Trace'
// });
//
//signalREventHandlers:
//
//
//const sessionStatusChanged = {
//     eventName: 'SessionStatusChanged',
//     actionName: ActionTypes.SIGNAL_R_INFO,
// };
//export default combineSignalREvents(sessionStatusChanged,sessionStatusChanged2);
//
//
//React.js inside provider:
//
//const token = useSelector(state => state.root.getIn(['appUser', 'token']));
//
//   useEffect(()=>{
//     token && signalRConnect(store, token);
//   },[token])



import {
  JsonHubProtocol,
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel
} from '@microsoft/signalr';
import * as ActionTypes from '../actions/types';

let connection;

const stopSignalRConnection = connection =>
  connection
    .stop()
    .then(() => console.info('SignalR Connection stopped'))
    .catch(err => console.info('SignalR Stopping Connection Error: ', err));

const startSignalRConnection = connection => connection.start()
  .then(() => console.info('SignalR Connected'))
  .catch(err => console.error('SignalR Connection Error: ', err));

const signalRConnect = (store, token) => {
  store.dispatch({
    type: "SIGNALR_CONNECT",
    payload: token,
  });
};

function combineSignalREvents(){
  let eventsList = {};
  for ( let i=0; i<arguments.length; i++)
    eventsList = {...eventsList, [i]: arguments[i]};
  return eventsList;
}

const createSignalRMiddleware = (args) => {
  return (store) => next => async (action) => {
    // register signalR after the user logged in
    if (action.type === 'SIGNALR_CONNECT') {
      const connectionHub = args.url;

      const protocol = new JsonHubProtocol();

      // let transport to fall back to to LongPolling if it needs to
      const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;
      const options = {
        transport,
        logMessageContent: args.logMessageContent ? args.logMessageContent : false ,
        logger: args.logger ? LogLevel[args.logger] : LogLevel.None,
        accessTokenFactory: () => action.payload
      };

      // create the connection instance
      connection = new HubConnectionBuilder()
        .withUrl(connectionHub, options)
        .withHubProtocol(protocol)
        .build();

      // event handlers, you can use these to dispatch actions to update your Redux store
      for (let seh in args.signalREventHandlers) {
        connection.on(args.signalREventHandlers[seh].eventName, (res) => {
              store.dispatch({type: args.signalREventHandlers[seh].actionName, payload: JSON.parse(res)});
        }
        );
      }

      // re-establish the connection if connection dropped
      connection.onclose(error => {
        if (typeof error !== 'undefined') {
          setTimeout(startSignalRConnection(connection), 5000);
        } else {
          console.log(
            'Client closed the WS connection, so no reconnect attempt is made',
          );
        }
      });

      startSignalRConnection(connection);
    }

    if (action.type === ActionTypes.SIGNAL_R_DISCONNECT) {
      stopSignalRConnection(connection);
    }

    return next(action);
  };
};

export {
  signalRConnect,
  createSignalRMiddleware,
  combineSignalREvents
};

