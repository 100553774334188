import React from 'react';

type Props = {
  pictureUri: string,
  title: string,
  action: Function,
};

const ThingToDo = (props: Props) => {
  const { title, pictureUri, action} = props;
  return (
    <div
      className="thingToDo"
      onClick={action}
    >
      <img src={pictureUri} alt={title} />
      <div>
      <span>
        {title}
      </span>
      </div>
    </div>
  );
};

export default ThingToDo;
