export const postHeaderSimpleJson = () => ({
  accept: 'application/json',
  "CONTENT-TYPE": "application/json",
});

export const postHeaderWithTokenJson = (token: string) => {
  return ({
    accept: 'application/json',
    "CONTENT-TYPE": "application/json",
    'Authorization': `Bearer ${token}`,
  });
};
