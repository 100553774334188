import React from 'react';
import './App.css';
import LayoutHOC from './containers/LayoutHOC';
import PageVisibility from 'react-page-visibility';
import { connect } from 'react-redux';
import { setTabVisibility } from './actions';

type Props = {
  setTabVisibility: Function,
};

class App extends React.Component<Props> {
  handleVisibilityChange = isVisible => {
    this.props.setTabVisibility(isVisible);
  };

  render() {
    return (
      <PageVisibility onChange={this.handleVisibilityChange}>
      <LayoutHOC />
      </PageVisibility>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setTabVisibility: (payload) => {
    dispatch(setTabVisibility(payload))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
