import PagesTypes from './pagesTypes';

const screens = {
  VIDEOS: {
    text: 'Βίντεο',
    id: PagesTypes.VIDEO,
  },
  ARTICLES: {
    text: 'Άρθρα',
    id: PagesTypes.ARTICLE,
  }
};

export default screens;
