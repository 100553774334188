import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './assets/scss/base.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store, { persistor } from './store/store';
import { PersistGate } from "redux-persist/es/integration/react";
import LoadingScreen from './components/general/loadingScreen/loadingScreen';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<LoadingScreen />} persistor={persistor}>
  <App />
    </PersistGate>
  </Provider>
  , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(); // Change this for offline
