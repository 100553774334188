import React from 'react';
import './message.css';
import { emojify } from 'react-emojione';
import { connect } from 'react-redux';
import Images from '../../assets/images';

type Props = {
  data: Object,
  isMine: boolean,
  startsSequence: boolean,
  endsSequence: boolean,
  showTimestamp: boolean,
  sendingInProgress?: boolean,
  visible: boolean,
  allMessages: Array<Object>,
};

class Message extends React.Component<Props> {
  componentDidMount(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (!this.props.isMine && !this.props.visible) {
      try {
        let title = 'Έχεις ένα νέο μήνυμα!';
        let options = {
          badge: Images.LOGO_BADGE,
          body: this.props.data.message,
          icon: Images.LOGO_PNG,
        };

        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready.then(registration => {
            registration.showNotification(title, options);
          });
        } else {
          console.log('den ton blepei');
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  render() {
    const {
      data,
      isMine,
      startsSequence,
      endsSequence,
      showTimestamp,
      sendingInProgress
    } = this.props;

    if (data) {
      return (
        <div className={[
          'message',
          `${isMine ? 'mine' : ''}`,
          `${startsSequence ? 'start' : ''}`,
          `${endsSequence ? 'end' : ''}`,
          `${sendingInProgress ? 'sending' : ''}`,
        ].join(' ')}>
          {
            showTimestamp &&
            <div
              className={`timestamp
          ${isMine ? ' justifyContentFlexEnd' :
                ' justifyContentFlexStart'}`}>
              { data.timestamp }
            </div>
          }
          {sendingInProgress ?
            <div className="bubble-container withError">
              <div className="bubble" title={data.timestamp}>
                { data.message }
              </div>
              <div
                className="waitingCircle deliveryStatus"
              />
            </div> : <div className="bubble-container">
              <div className="bubble" title={data.timestamp}>
                { emojify(data.message) }
              </div>
            </div>}

        </div>
      );
    } return null;
  }
}

const mapStateToProps = state => ({
  visible: state.general.get('visible'),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Message);
