// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import LoginScreen from '../components/login/loginScreen';
import { registerChild } from '../actions';

type Props = {
  registerChild: Function,
  loading: boolean,
  errorMessage?: string,
  acceptTerms: boolean,
  token: string,
  history: Object,
};

type State = {
  nickname: string,
  ageSelected?: string,
  isAgeFocused: boolean,
  validationErrorMessage?: string,
}

class LoginHOC extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      nickname: '',
      ageSelected: undefined,
      isAgeFocused: false,
      validationErrorMessage: undefined,
    };
  }

  handleChange = (event) => {
    this.setState({nickname: event.target.value});
  };

  selectAge = (event) => {
    this.setState({ageSelected: event.currentTarget.title});
  };

  giveFocusToAge = (event) => {
    this.setState({isAgeFocused: true});
  };

  loseFocus = (event) => {
    this.setState({isAgeFocused: false});
  };

  setValidationErrorMessage = (message) => {
    this.setState({validationErrorMessage: message});
  };

  componentDidMount() {
    if(this.props.token && this.props.acceptTerms){
      this.props.history.push('/chatHomeScreen')
    } else if(this.props.token && !this.props.acceptTerms){
      this.props.history.push('/terms')
    }
  }

  render() {
    return (
      <LoginScreen
        nickname={this.state.nickname}
        onNicknameChange={this.handleChange}
        onAgeChange={this.selectAge}
        ageSelected={this.state.ageSelected}
        isAgeFocused={this.state.isAgeFocused}
        giveFocusToAge={this.giveFocusToAge}
        loseFocus={this.loseFocus}
        setValidationErrorMessage={this.setValidationErrorMessage}
        validationErrorMessage={this.state.validationErrorMessage}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => ({
  loading: state.user.get('loading'),
  errorMessage: state.user.get('errorMessage'),
  token: state.user.get("token"),
  acceptTerms: state.terms.get("acceptTerms"),
});

const mapDispatchToProps = dispatch => ({
  registerChild: (payload) => {
    dispatch(registerChild(payload))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginHOC);
