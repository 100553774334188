import React from 'react';
import './noConnectivity.css';

const NoConnectivity = () => {
  return (
    <div className="noConnectivity">Παρακαλώ ελέγξτε την συνδεσιμότητα!</div>
  );
};

export default NoConnectivity;
