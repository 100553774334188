import React from 'react';

type Props = {
  age: string,
  onClick: Function,
  id: number,
  ageSelected: string,
  ageTitle: string,
};

const AgeCircle = (props: Props) => {
  const { age, onClick, id, ageSelected, ageTitle } = props;
  return (
    <div
      className={`ageCircleOuter ${ageSelected === ageTitle ? ' selected' : ''}`}
      onClick={onClick}
      id={id}
      title={ageTitle}
    >
      <div
        className="ageCircleInner"
      >
      <span>
      {age}
        </span>
      </div>
    </div>
  );
};

export default AgeCircle;
