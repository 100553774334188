import React from 'react';
import createMarkup from '../../helpers/createHtmlMarkup';

type Props = {
  thingToDo: Object,
  category: string,
  history: Object,
};

const ThingToDo = (props: Props) => {
  const { thingToDo, history, category } = props;
  return (
    <div
      className="singleThingToDo"
      onClick={() => history.push(`/thingsToDo/${category.toLowerCase() + 's'}/${thingToDo.id}`)}
    >
      <span>
      {thingToDo.title}
      </span>
    </div>
  );
};

export default ThingToDo;
