import React from 'react';
import './titleValueCard.css';

type Props = {
  title: string,
  value: string,
};

const TitleValueCard = (props: Props) => {
  const { title, value } = props;
  return (
    <div className="titleValueCard">
      <h1>{title}</h1>
      <p>
        {value}
      </p>
    </div>
  );
};

export default TitleValueCard;
