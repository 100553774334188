import React from 'react';

type Props = {
  checked: boolean,
  handleCheckboxChange: Function,
};

const AcceptTermsSticky = (props: Props) => {
  const { checked, handleCheckboxChange } = props;

  return (
    <div className="acceptTermsSticky">
      <span>
      Αποδέχομαι τους κανόνες λειτουργίας για τη<br />
        συμμετοχή μου στο CHAT 1056
      </span>
      <input
        className="checkboxAgreesToTerms"
        name="agrees"
        type="checkbox"
        checked={checked}
        onChange={handleCheckboxChange} />
    </div>
  );
};

export default AcceptTermsSticky;
