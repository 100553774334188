// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { getSosLinePages } from '../actions';
import SosLineScreen from '../components/sosLine/sosLineScreen';

type Props = {
  getSosLinePages: Function,
  title: string,
  body: string,
  loading: boolean,
};

class SosLineHOC extends React.Component<Props> {
  componentDidMount() {
    return this.props.getSosLinePages();
  }

  render() {
    const { title, body, loading } = this.props;
    return (
      <SosLineScreen
        title={title}
        body={body}
        loading={loading}
      />
    );
  }
}

const mapStateToProps = state => ({
  title: state.sosLine.get('title'),
  body: state.sosLine.get('body'),
  loading: state.sosLine.get('loading'),
});

const mapDispatchToProps = dispatch => ({
  getSosLinePages: (payload) => {
    dispatch(getSosLinePages(payload))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SosLineHOC);
