import * as React from 'react';
import { connect } from 'react-redux';
import ChatScreen from '../components/chat/chatScreen';
import { getMessages, sendMessage } from '../actions';
import getTimeRemaining from '../helpers/countdownTimer';
import parseMessages from '../components/immutableParsers/parseMessages';

type Props = {
  sendMessage: Function,
  getMessages: Function,
  messages: Object,
  chatEndTime: Object,
  isTabVisible: boolean,
};

type State = {
  timeRemaining?: Object,
}

let HANDLE = undefined;
let HANDLE2 = undefined;

class ChatHOC extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      timeRemaining: undefined,
    };
  }

  componentDidMount() {
    this.props.getMessages();
    HANDLE = setInterval(this.updateTime, 1000);
    HANDLE2 = setInterval(this.props.getMessages, 10000);
  }

  updateTime = () => {
    // This will run if an end time has been specified and we have not passed it yet
    if (this.props.chatEndTime) {
      const timeRemaining = getTimeRemaining(this.props.chatEndTime);
      if (timeRemaining.total === 0) {
        clearInterval(HANDLE);
        clearInterval(HANDLE2);
      }
        this.setState({
          timeRemaining
        });
    }
  };

  componentWillUnmount() {
    clearInterval(HANDLE);
    clearInterval(HANDLE2);
  }

  render() {
    return (
      <ChatScreen
        messages={parseMessages(this.props.messages)}
        sendMessage={this.props.sendMessage}
        timeRemaining={this.state.timeRemaining}
      />
    );
  }
}

const mapStateToProps = state => ({
  messages: state.chat.get('messages'),
  chatEndTime: state.chat.get('endTime'),
  isTabVisible: state.general.get('visible')
});

const mapDispatchToProps = dispatch => ({
  sendMessage: (payload) => {
    dispatch(sendMessage(payload))
  },
  getMessages: (payload) => {
    dispatch(getMessages(payload))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatHOC);
