import React from 'react';
import './article.css';
import Unauthorized from '../unauthorized/unauthorized';
import createMarkup from '../../helpers/createHtmlMarkup';

type Props = {
  article: Object,
};

const ArticleScreen = (props: Props) => {
  const { article } = props;

  if (article)
  return (
    <div className="articleScreenContainer">
      <h1>{article.title}</h1>
      <div className="articleBody" dangerouslySetInnerHTML={createMarkup(article.body)} />
    </div>
  );

  return (<Unauthorized />);
};

export default ArticleScreen;
