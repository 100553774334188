// @flow
import React from 'react';
// import './button.css';
import { debounce } from 'lodash';

type Props = {
  modifiers?: any,
  className: string,
  children: any,
  onClick?: Function,
  color: string,
};

const Button = (props: Props) => {
  const { modifiers, className, children, color, ...buttonProps } = props;

  const debounced = props.onClick ? debounce(props.onClick, 500) : undefined;
  return (
    <button
      {...buttonProps}
      className={"button-" + className}
      style={{
        backgroundColor: color,
      }}
      onClick={debounced}
    >
      {children}
    </button>
  );
};

export default Button;
