const AGES = [
  {
    title: "ElevenMinus",
    age: "11-",
  },{
    title: "Twelve",
    age: "12",
  },{
    title: "Thirteen",
    age: "13"
  },{
    title: "Fourteen",
    age: "14"
  },{
    title: "Fifteen",
    age: "15"
  },{
    title: "Sixteen",
    age: "16"
  },{
    title: "Seventeen",
    age: "17"
  },{
    title: "EighteenAndOver",
    age: "18+"
  },
];

export default AGES;
