// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import TermsScreen from '../components/terms/termsScreen';
import {acceptTerms, getTerms} from '../actions';

type Props = {
  acceptTerms: Function,
  getTerms: Function,
  loading: boolean,
  title: string,
  body: string,
};

type State = {
  checked: boolean,
}

class TermsHOC extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  componentDidMount(){
    setTimeout(()=>{this.props.getTerms()}, 1);
  }

  handleCheckboxChange = () => {
    this.setState({checked: !this.state.checked});
    setTimeout(() => {this.props.acceptTerms()}, 500);
  };

  render() {
    return (
      <TermsScreen
        title={this.props.title}
        body={this.props.body}
        checked={this.state.checked}
        handleCheckboxChange={this.handleCheckboxChange}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => ({
  loading: state.terms.get('loading'),
  title: state.terms.get('title'),
  body: state.terms.get('body'),
});

const mapDispatchToProps = dispatch => ({
  getTerms: (payload) => {
    dispatch(getTerms(payload))
  },
  acceptTerms: (payload) => {
    dispatch(acceptTerms(payload))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsHOC);
