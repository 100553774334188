import React from 'react';
import TermsHeader from './termsHeader';
import TitleValueCard from '../general/cards/titleValueCard';
import Terms from './terms';
import LoadingScreen from '../general/loadingScreen/loadingScreen';
import './terms.css';

type Props = {
  loading: boolean,
  title: string,
  body: string,
};

const ViewTermsScreen = (props: Props) => {
  const { loading, title, body } = props;

  if (loading) {
    return <LoadingScreen />
  }

  return (
    <div>
      <TermsHeader/>
      <div className="termsContent">
        <TitleValueCard
          title={title}
          value="Ακολουθούν οι όροι χρήσης του chat"
        />
        <div className="termsContainer">
          <Terms
            body={body}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewTermsScreen;
