import { fromJS } from 'immutable';
import * as ActionTypes from '../actions/types';
import { createReducer } from './index';

const INITIAL_STATE = fromJS({
  visible: true,
  userAgent: undefined,
  isMobileSafari: false,
});

function setVisibility(state, action) {
  return state.set('visible', action.payload);
}

function setUserAgentInfo(state, action) {
  let isMobileSafari = false;

  if (action.payload
  && action.payload.browser.name === "Safari"
  && action.payload.os.name === "iOS"
  && action.payload.platform.type === "mobile"
  ) {
    isMobileSafari = true;
  }

  return state
    .set('userAgent', action.payload)
    .set('isMobileSafari', isMobileSafari);
}

export const general = createReducer(INITIAL_STATE, {
  [ActionTypes.SET_TAB_VISIBILITY]: setVisibility,
  [ActionTypes.SET_USER_AGENT_INFO]: setUserAgentInfo,
});
