import { fromJS } from 'immutable';
import * as ActionTypes from '../actions/types';
import { createReducer } from './index';
import ThingsToDoScreens from '../constants/thingsToDoScreens';

const INITIAL_STATE = fromJS({
  screenSelected: ThingsToDoScreens.VIDEOS.id,
});

function selectScreenInThingsToDo(state, action) {
  return state.set('screenSelected', action.payload)
}

function returnInitialState() {
  return INITIAL_STATE;
}

export const thingsToDo = createReducer(INITIAL_STATE, {
  [ActionTypes.SELECT_SCREEN_IN_THINGS_TO_DO]: selectScreenInThingsToDo,
  [ActionTypes.LOGOUT]: returnInitialState,
});
