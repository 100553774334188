import React, {Component} from 'react';
import _ from 'lodash';
import { header } from '@cycle/dom';

type MediaQueryConfiguration = {
    sm?: MediaQueryComponent,
    md?: MediaQueryComponent,
    lg?: MediaQueryComponent,
    xl?: MediaQueryComponent,
    xxl?: MediaQueryComponent,
};

type MediaQueryComponent = {
    Component: any,
    props: Object
};

type Props = {
    defaultComponentBreakpoint: string,//sm,md,lg,xl,xxl
    config: MediaQueryConfiguration
};

const MEDIA_BREAKPOINTS = {
    sm: 450,
    md: 451,
};

export const getMatchingMediaBreakpoint = (config, defaultOption) => {
    if (!config) return defaultOption;
    const selected = _.findLast(
        Object.keys(config), (
            (breakpoint) => mediaQueryApplies(MEDIA_BREAKPOINTS[breakpoint])
        )
    );
    return selected ? selected : defaultOption;
};

export const mediaQueryApplies = (breakpoint) => {
    const query = window.matchMedia("only screen and (max-height:" + breakpoint + "px)");
    return window.innerHeight > breakpoint;
};

class SwitchTheme extends Component<Props> {
    state = {
        selectedBreakpoint: null
    };

    updateSelectedBreakpoint = () => {
        // alert(window.innerHeight);
        const {config, defaultComponentBreakpoint } = this.props;
        this.setState({
            selectedBreakpoint: getMatchingMediaBreakpoint(config, defaultComponentBreakpoint)
        })
    };

    componentDidMount() {
        this.updateSelectedBreakpoint();
        window.addEventListener("resize", this.updateSelectedBreakpoint.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateSelectedBreakpoint.bind(this));
    }

    render() {
        const {selectedBreakpoint} = this.state;
        if (!selectedBreakpoint) return null;

        const {config} = this.props;
        const {Component, props} = config[selectedBreakpoint];
        if (!Component) return null;
        return (
            <Component {...props} />
        );
    }
}

export default SwitchTheme;
