import { combineCycles } from 'redux-cycles';
import xs from 'xstream';
import * as actions from '../actions'
import * as ActionTypes from '../actions/types';
import { BASE_URL} from '../config';
import { postHeaderSimpleJson } from './headers';

export function registerChild(sources) {
  const request$ = sources.ACTION
    .filter(action => action.type === ActionTypes.REGISTER_CHILD)
    .map(action => ({
      url: BASE_URL + 'ApplicationUsers/registerChild',
      category: 'registerChild',
      method: 'POST',
      header: postHeaderSimpleJson(),
      send: {
        displayName: action.payload.displayName,
        age: action.payload.age,
      }
    }));

  let httpResponse$ = sources.HTTP
    .select('registerChild')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 201)
    .map(response => actions.registerChildSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  }
}

export function registerChildFailed(sources) {
  const response$ = sources.HTTP
    .select('registerChild')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 201);

  const action$ = xs.combine(response$)
    .map(response => actions.registerChildFailed(response));

  return {
    ACTION: action$,
  };
}

export function disconnectFromSignalRWhenUserLogsOut(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.LOGOUT)
    .map(() => actions.signalRDisconnect());

  return {
    ACTION: action$,
  }
}

export default combineCycles(
  registerChild,
  registerChildFailed,
  disconnectFromSignalRWhenUserLogsOut
);
