import { fromJS } from 'immutable';
import * as ActionTypes from '../actions/types';
import { createReducer } from './index';
import PagesTypes from '../constants/pagesTypes';

const INITIAL_STATE = fromJS({
  [PagesTypes.ARTICLE]: [],
  [PagesTypes.GAMES]: [],
  [PagesTypes.STATIC]: [],
  [PagesTypes.VIDEO]: [],
});

function getPages(state, action) {
  return state;
}

function getPagesSuccess(state, action) {
  try {
    const responseData = action.payload.body.results;
    const articles = responseData
      .filter(responseItem =>
        responseItem.type === PagesTypes.ARTICLE
      );
    const games = responseData
      .filter(responseItem =>
        responseItem.type === PagesTypes.GAMES
      );
    const staticPages = responseData
      .filter(responseItem =>
        responseItem.type === PagesTypes.STATIC
      );
    const videos = responseData
      .filter(responseItem =>
        responseItem.type === PagesTypes.VIDEO
      );

    if (action.payload.body.page === 1) { // Refresh state
      return state
        .set(PagesTypes.ARTICLE, fromJS(articles))
        .set(PagesTypes.GAMES, fromJS(games))
        .set(PagesTypes.STATIC, fromJS(staticPages))
        .set(PagesTypes.VIDEO, fromJS(videos));
    }
    return state
      .set(
        PagesTypes.ARTICLE,
        state.get(PagesTypes.ARTICLE).push(...fromJS(articles)),
      )
      .set(PagesTypes.GAMES, state.get(PagesTypes.GAMES).push(...fromJS(games)))
      .set(
        PagesTypes.STATIC,
        state.get(PagesTypes.STATIC).push(...fromJS(staticPages)),
      )
      .set(
        PagesTypes.VIDEO,
        state.get(PagesTypes.VIDEO).push(...fromJS(videos)),
      );
  } catch (e) {
    console.log('getPages Success FAILED');
    return state;
  }
}

function getPagesFailed(state, action) {
  return state;
}

export const pages = createReducer(INITIAL_STATE, {
  [ActionTypes.GET_PAGES]: getPages,
  [ActionTypes.GET_PAGES_SUCCESS]: getPagesSuccess,
  [ActionTypes.GET_PAGES_FAILED]: getPagesFailed,
});
