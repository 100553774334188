// @flow
import React, { useState, useEffect } from 'react';
import HeaderTitle from './headerTitle';
import HeaderIcon from './headerIcon';
import './header.css';
import { faChevronLeft, faChild } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import CallButton from '../footer/callButton';
import SwitchTheme from '../switchTheme/switchTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../../style/colors';

type Props = {
  role: string,
  chatId: string,
  endTime: number,
};

const Header = (props: Props) => {
  const [icon, setIcon] = useState("faChild");
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname !== "/"
      && history.location.pathname !== "/chatHomeScreen"
      && history.location.pathname !== "/terms"
      && history.location.pathname !== "/chat"
    ) {
      if (history.location.pathname === "/thingsToDo" && props.chatId) {
        if (icon !== "faChild") {
          setIcon("faChild")
        }
      } else {
        if (icon !== "faChevronLeft") {
          setIcon("faChevronLeft");
        }
      }
    } else {
      if (icon !== "faChild") {
        setIcon("faChild")
      }
    }
  });

  return (
    <div className="header">
      {history.location.pathname !== "/chat"
      && props.endTime ?
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div
            onClick={() => {
              history.push('/chat')
            }}
          >
            <FontAwesomeIcon
              className=""
              icon={faChevronLeft}
              color={Colors.BLACK}
              style={{
                width: 25,
                height: 25
              }}
            />
          </div>
          <div
            onClick={() => history.push('/chat')}
            style={{
              flex: 1,
              fontWeight: 'bold'
            }}
          >
            ΕΠΙΣΤΡΟΦΗ ΣΤΟ CHAT
          </div>
        </div>
          :
        <HeaderIcon history={history} goBack={icon !== "faChild"}
                    iconName={icon === "faChild" ? faChild : faChevronLeft}/>
      }
      <HeaderTitle/>
      <SwitchTheme
        defaultComponentBreakpoint="sm"
        config={{
          sm: {
            Component: CallButton
          },
          md: {
            Component: 'a'
          },
        }}
      />
    </div>
  );
};

export default Header;
