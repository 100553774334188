import React from 'react';
import './video.css';
import Unauthorized from '../unauthorized/unauthorized';
import YoutubeVideoPlayer from './videoPlayer';
import createMarkup from '../../helpers/createHtmlMarkup';

type Props = {
  video: Object,
};

const VideoScreen = (props: Props) => {
  const { video } = props;

  if (video)
  return (
    <div>
      <h1>{video.title}</h1>
      <div className="videoBody" dangerouslySetInnerHTML={createMarkup(video.body)} />
      {video.url ? <YoutubeVideoPlayer youtubeId={video.url}/> : null}
    </div>
  );

  return (<Unauthorized />);
};

export default VideoScreen;
