import React from 'react';
import createMarkup from '../../helpers/createHtmlMarkup';
import LoadingScreen from '../general/loadingScreen/loadingScreen';
import './sosLineScreen.css';

type Props = {
  title: string,
  body: string,
  loading: boolean,
};

const SosLineScreen = (props: Props) => {
  const { title, body, loading } = props;

  if (loading) {
    return <LoadingScreen />
  }
  return (
    <div>
      <h1>{title}</h1>
      <div
        className="sosLineBodyContainer"
        dangerouslySetInnerHTML={createMarkup(body)} />
    </div>
  );
};

export default SosLineScreen;
